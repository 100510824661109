import {
    Overflow,
    OverflowItem,
    Subtitle1,
    TabList,
    Tab,
    Text,
    Title3,
    makeStyles,
    shorthands,
    tokens,
    Button,
    Body1Strong,
    Image,
    MessageBar,
    MessageBarBody,
    MessageBarTitle,
    Divider,
    Menu,
    MenuTrigger,
    MenuPopover,
    MenuList,
    MenuItem
} from "@fluentui/react-components";
import {
    Box20Regular,
    ChatWarningRegular,
    SettingsRegular,
    NavigationRegular,
    HomeRegular,
    DocumentSearchRegular,
    AppsListDetailRegular,
    QuestionRegular,
    TaskListLtrRegular
} from "@fluentui/react-icons";
import { IPagelayoutProps } from "./page-layout.props";
import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { IMenuItem } from "@interfaces/common.interfaces";
import navItems from "lib/nav-items";
import { getTopLevelMenuItems } from "lib/utils";
import { PPAContext } from "context/ppa-context";
import ScreenPlaceholder from "components/common/screen-placeholder/screen-placeholder";
import Disabled from "components/initialising-ppa/disabled";
import LicenceExpired from "components/initialising-ppa/licence-expired";
import Breadcrumb from "components/navigation/breadcrumb/breadcrumb";

//import navItemsV2 from "lib/nav-items-v2";
//import navItems from "lib/nav-items";

const layoutStyles = makeStyles({
    mainWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    header: {
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: tokens.colorNeutralStrokeSubtle,
        //display: 'flex',
        display: 'flex',
        ...shorthands.padding("0em", "1em"),
        alignItems: 'center',
    },
    headerDivider: {
        width: "1px",
        height: "75%",
        backgroundColor: tokens.colorNeutralStrokeSubtle,
        ...shorthands.margin(0, "0.5em"),
    },
    messageBar: {
        ...shorthands.margin("1em", "2em")
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
        ...shorthands.margin("1em", "2em"),
        "> button": {
            ...shorthands.padding(0)
        }
    },
    titleIcon: {
        marginRight: "0.5em"
    },
    menuWrapper: {
        display: 'flex',
        alignItems: 'flex-end',
        flexGrow: 1
    },
    menu: {
        display: 'flex',
        alignItems: 'center',
        height: '100%'
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        marginRight: '1em',
        borderBottomWidth: "3px",
        borderBottomStyle: "solid",
        borderBottomColor: 'transparent'
    },
    selected: {
        borderBottomColor: tokens.colorBrandForegroundLinkSelected,
    },
    rightButons: {
        ...shorthands.margin("auto", "2em")
    }
});



export default function PageLayout(props: PropsWithChildren<IPagelayoutProps>) {

    // TODO: Separate the header out??
    const ppaContext = useContext(PPAContext);

    const navigate = useNavigate();
    const location = useLocation();
    const styles = layoutStyles();

    //const [menuItems, setMenuItems] = useState<Array<IMenuItem>>(navItems.map(item => { return item as IMenuItem }));
    //const [menuItems, setMenuItems] = useState<Array<IMenuItem>>(navItems.filter(i => !i.parentId).map(item => { return item as IMenuItem }));
    const [menuItems, setMenuItems] = useState<Array<IMenuItem>>(getTopLevelMenuItems());

    // TODO: Get nav items from the main context


    useEffect(() => {
        var baseRoute = location.pathname.split('/').filter(p => p.length > 0)[0];
        const updated = menuItems.map(item => {
            return {
                ...item,
                isActive: item.route === `/${baseRoute}`
            }
        });
        setMenuItems(updated);
        console.log(`path -> `, location.pathname);
    }, [location.pathname]);

    return (
        <main className={styles.mainWrapper}>
            {ppaContext.hasAccess && ppaContext.isSignupComplete &&
                <div className={styles.header}>
                    <Menu>
                        <MenuTrigger>
                            <Button
                                appearance="transparent"
                                icon={<NavigationRegular />} />
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>
                                <MenuItem
                                    icon={<DocumentSearchRegular />}
                                    onClick={() => navigate('/reports')}>Reports</MenuItem>
                                <MenuItem
                                    icon={<AppsListDetailRegular />}
                                    onClick={() => navigate('/report-requests')}>Report requests</MenuItem>
                                <MenuItem
                                    icon={<TaskListLtrRegular />}
                                    onClick={() => navigate('/action-requests')}>Action requests</MenuItem>
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                    <div className={styles.headerDivider}></div>
                    <Button
                        appearance="transparent"
                        icon={<HomeRegular />}
                        onClick={() => navigate('/')} />
                    <Breadcrumb />
                    {/* <div className={styles.headerDivider}></div>
                    <Button
                        appearance="transparent"
                        icon={<QuestionRegular />} /> */}
                </div>}
            {ppaContext.ssoError &&
                <div className={styles.messageBar}>
                    <MessageBar intent="error">
                        <MessageBarBody>
                            <MessageBarTitle>{ppaContext.ssoError}</MessageBarTitle>
                        </MessageBarBody>
                    </MessageBar>
                </div>}
            {ppaContext.disabled && <Disabled />}
            {!ppaContext.disabled && ppaContext.licenceExpired && <LicenceExpired />}
            {ppaContext.hasAccess &&
                <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    {props.children}
                </div>}
        </main>
    );
}