import { IApiError, IMenuItem, INavItemV3, INavigationCardItem, SizeUnits } from "@interfaces/common.interfaces";
//import navItemsV2 from "./nav-items-v2";
//import reports from "./reports";
import navItems from "./nav-items";
import axios, { AxiosError } from "axios";


export const getTopLevelMenuItems = (): Array<IMenuItem> => {
    //
    const items = navItems.filter(n => n.onTopNav === true && n.disabled !== true);
    //
    return getMenuItems(items);
};


export const getTopLevelNavigationCardItems = (): Array<INavigationCardItem> => {
    //
    return getNavigationCardItemsForParent(undefined);
};

export const getNavigationCardItems = (route: string): Array<INavigationCardItem> => {
    //
    const parent = navItems.filter(n => n.route === route)[0];
    //
    return parent ? getNavigationCardItemsForParent(parent.id) : [];
}

const getMenuItems = (items: Array<INavItemV3>): Array<IMenuItem> => {
    return items
        .map((item): IMenuItem => {
            return {
                id: item.id,
                route: item.route,
                label: item.label,
                isActive: false
            };
        });
};

const getNavigationCardItemsForParent = (parentId: string | undefined): Array<INavigationCardItem> => {
    return navItems
        .filter(n => n.parentId === parentId && n.disabled !== true)
        .map((item) => {
            const childItems = navItems.filter(n => n.parentId === item.id && n.disabled !== true);
            return {
                id: item.id,
                route: item.route,
                title: item.label,
                description: item.description || "",
                icon: item.icon,
                actionLabel: childItems.length > 0 ? "Open" : "View",
                childItems: childItems
                    .map((childItem) => {
                        return {
                            id: childItem.id,
                            route: childItem.route,
                            title: childItem.label,
                            description: childItem.description,
                            icon: childItem.icon
                        }
                    })
            };
        });
};

export const getError = <T>(error: unknown | AxiosError<T>): IApiError => {
    const apiError: IApiError = {
        status: 400,
        message: "",
        description: "",
        correlationId: "",
        timeStamp: ""
    }
    if (axios.isAxiosError(error)) {
        apiError.status = error.response?.status || 400;
        apiError.message = error.message;
        apiError.description = `${error.message}</br>`;
        if (error.response?.data) {
            if (error.response.data.userError) {
                apiError.message = error.response.data.userError.message;
                apiError.correlationId = error.response.data.userError.correlationId;
                apiError.timeStamp = error.response.data.userError.timeStamp;
            }
            if (error.response.data.validationErrors) {
                (error.response.data.validationErrors as Array<any>).forEach((error: any) => {
                    apiError.description += `${error.message}</br>`;
                });
            }
            if (error.response.data.errorMessages) {
                (error.response.data.errorMessages as Array<any>).forEach((error: any) => {
                    apiError.description += `${error.message}</br>`;
                });
            }
        }
    }
    else {
        // This is basic until more error types can be identified and set based on their type.
        apiError.message = JSON.stringify(error);
    }
    return apiError;
}

/*
* Takes an object that can be json stringified and escapes it for use as an export column value.
* Required for any objects that will contain a comma after stringifying.
*/
export const escapeForExport = (value: object) => {
    // Do a straight stringify to get the string value from the object json.
    const stringValue = JSON.stringify(value);
    // Escape all the double quotes (") by changing to double double quotes ("").
    const escapedString = stringValue.replaceAll('"', '""');
    // Wrap it all in double quotes to return.
    return `"${escapedString}"`;
}


export const getSizeLabel = (value: number, units: SizeUnits): string => {

    let workingValue = value;

    const availableUnits = ["B", "KB", "MB", "GB", "TB"];

    let count = availableUnits.indexOf(units) || 0;

    while (workingValue > 1000) {
        workingValue = workingValue / 1024;
        count++;
        //console.log(`workingValue ->`, workingValue);
    }

    // TODO: Something if Bytes, convert to KB
    // Then if less than 0.1 but not 0, show as < 0.1 KB

    //setValue(workingValue.toFixed(2).replace(".00", ""));
    //setUnits(us[count]);

    return `${workingValue?.toFixed(2).replace(".00", "")} ${availableUnits[count]}`
}

export const getWorkspaceImageUrl = (workspaceType: number | undefined): string => {
    switch (workspaceType) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
            return "images/SharePointLogo.svg";
        case 5:
            return "images/TeamsLogo.svg";
        case 6:
            return "images/TeamsPrivateChannelLogo.png";
        case 7:
            return "images/TeamsSharedChannelLogo.png";
        default:
            return "images/SharePointLogo.svg";
    }
}


// const getNavigationCardItemsForParent = (route: string): Array<INavigationCardItem> => {
//     //
//     const parent = navItems.filter(n => n.route === route)[0];
//     //
//     return parent ?
//         navItems
//             .filter(n => n.parentId === parent.id && n.disabled !== true)
//             .map((item) => {
//                 const childItems = navItems.filter(n => n.parentId === item.id && n.disabled !== true);
//                 return {
//                     id: item.id,
//                     route: item.route,
//                     title: item.label,
//                     description: item.description || "",
//                     icon: item.icon,
//                     //actionLabel: item.isReport ? "Open" : "View",
//                     actionLabel: childItems.length > 0 ? "Open" : "View",
//                     childItems: childItems
//                         .map((childItem) => {
//                             return {
//                                 id: childItem.id,
//                                 route: childItem.route,
//                                 title: childItem.label,
//                                 description: childItem.description,
//                                 icon: childItem.icon
//                             }
//                         })
//                 };
//             })
//         :
//         [];
// };


// export const getReportNavigationCardItems = (id: string): Array<INavigationCardItem> => {
//     return reports
//         .filter(n => n.parentId === id && n.enabled === true)
//         .map((item) => {
//             return {
//                 id: item.id,
//                 route: item.route,
//                 title: item.label,
//                 description: item.description || "",
//                 icon: item.icon,
//                 actionLabel: item.isReport ? "Open" : "View",
//                 childItems: reports
//                     .filter(n => n.parentId === item.id && n.enabled === true)
//                     .map((childItem) => {
//                         return {
//                             id: childItem.id,
//                             route: childItem.route,
//                             title: childItem.label,
//                             description: childItem.description,
//                             icon: childItem.icon
//                         }
//                     })
//             };
//         });
// };