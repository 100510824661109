//import navItems from "lib/nav-items";
import ReportProgress from "components/reports-common/report-progress/report-progress";
//import NavigationCards from "../../../navigation/navigation-cards/navigation-cards";
import { useContext, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { format, isDate, parse, parseISO, toDate } from 'date-fns';
import DateTimeCell from "components/reports-common/report-results-grid-cells/date-time-cell/date-time-cell";
import { IRequestModel } from "@interfaces/reports/reports-common.interfaces";
import { ITableColumn, IWorkspace } from "@interfaces/common.interfaces";
import { IReportSectionProps } from "components/reports-common/report-section/report-section.props";
import { IGuestUsersInTenantReportDetails, IGuestUsersInTenantReportItem, IGuestUsersInWorkspaceReportItem } from "@interfaces/reports/guest-user-reports.interfaces";
import ReportSection from "components/reports-common/report-section/report-section";
import WorkspacePicker from "components/controls/workspace-picker/workspace-picker";
import CheckedCell from "components/reports-common/report-results-grid-cells/checked-cell/checked-cell";

// TODO: Somewhere central. e.g. in the navitems????
const reportUrl: string = "/api/reports/guest-users-in-tenant";

// TODO: Each column defined somewhere central and put together here.
const cols: Array<ITableColumn<IGuestUsersInTenantReportItem>> = [
  { key: "displayName", label: "Display name", showInGrid: true, showInExport: true, minWidth: 100 },
  { key: "email", label: "Email", showInGrid: true, showInExport: true, minWidth: 100, idealWidth: 200 },
  { key: "upn", label: "Upn", showInGrid: false, showInExport: true, minWidth: 100, idealWidth: 300 },
  { key: "objectId", label: "Entra user id", showInGrid: false, showInExport: true },
  { key: "memberOfName", label: "Name", showInGrid: true, showInExport: true, minWidth: 200, idealWidth: 300 },
  { key: "memberOfUrl", label: "Url", showInGrid: true, showInExport: true, minWidth: 200, idealWidth: 400 },
  { key: "memberOfId", label: "Id", showInGrid: false, showInExport: true, minWidth: 100 },
  { key: "memberOfType", label: "Type", showInGrid: true, showInExport: true, minWidth: 100 }, // TODO: Maybe this is an icon column?
  //{ key: "groupType", label: "Group type", showInGrid: true, showInExport: true, minWidth: 100 },
  //{ key: "groupName", label: "Group name", showInGrid: true, showInExport: true, minWidth: 100 },
  { key: "memberOfRole", label: "Role", showInGrid: true, showInExport: true, minWidth: 100 },
  //{ key: "customPermission", label: "Custom permission", showInGrid: true, showInExport: true, minWidth: 150 },
  { key: "created", label: "Created", showInGrid: true, showInExport: true, renderCell: (key: string, item: IGuestUsersInTenantReportItem) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />) },
  { key: "deleted", label: "Deleted", showInGrid: false, showInExport: true, renderCell: (key: string, item: IGuestUsersInTenantReportItem) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />) },
  { key: "pendingAcceptance", label: "Pending", showInGrid: true, showInExport: true, renderCell: (key: string, item: IGuestUsersInTenantReportItem) => (<CheckedCell columnId={key} item={item} checkedValue={true} />) },
  { key: "accountDisabled", label: "Disabled", showInGrid: true, showInExport: true, renderCell: (key: string, item: IGuestUsersInTenantReportItem) => (<CheckedCell columnId={key} item={item} checkedValue={true} />) },
  { key: "orphaned", label: "Orphaned", showInGrid: true, showInExport: true },
  { key: "lastLogin", label: "Last login", showInGrid: false, showInExport: false } // TODO: Only if data available/P1 licence. Maybe a flag in settings? Or check the tenant settings for P1 lic?
];

export default function GuestUsersInTenantReport() {

  const [model, setModel] = useState<IRequestModel>({});
  //const [selectedWorkspace, setSelectedWorkspace] = useState<IWorkspace>();

  // const onWorkspaceChange = (value?: IWorkspace) => {
  //   console.log(`GuestUsersInTenantReport -> onWorkspaceChange -> `, value);
  //   setModel({ ...model, workspace: { siteUrl: value?.siteUrl } });
  //   setSelectedWorkspace(value);
  // }

  const isModelValid = (): boolean => {
    return true;
  }

  const reportSectionProps: IReportSectionProps<IGuestUsersInTenantReportItem, IGuestUsersInTenantReportDetails> = {
    columns: cols,
    summaryComponent: <></>,
    reportUrl: reportUrl,
    model: model,
    isModelValid: isModelValid(),
    noItemsMessage: "There are no guest users found in the tenant.",
    exportFilenamePrefix: "GuestUsersInTenantReport"
  }

  return (
    <ReportSection {...reportSectionProps}>
      {/* <WorkspacePicker onChange={onWorkspaceChange} model={selectedWorkspace} /> */}
    </ReportSection>
  );
}
