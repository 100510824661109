import { useContext } from "react";

// TODO: Fix this path!!
//import PageLayout from "@components/Layout/PageLayout";
import PageLayout from "../layout/page-layout";


export default function Error404() {
  return (
    <PageLayout>
      <div style={{ margin: '1em 2em' }}>Not found</div>
    </PageLayout>
  );
}
