import config from "lib/config";
import { AxiosInstance, BearerTokenAuthProvider, TeamsUserCredential, createApiClient } from "@microsoft/teamsfx";
import axios, { AxiosError } from "axios";
import { PropsWithChildren, createContext, useEffect, useState } from "react";
import { useTeams } from "@microsoft/teamsfx-react";
import { ISignup } from "@interfaces/signup.interfaces";
import { ITenant } from "@interfaces/tenant.interfaces";
import { differenceInSeconds, parseISO } from "date-fns";


export interface IPPAContext {
    //signup: ISignup | null;
    //tenant: ITenant | null;
    //loadedSignup: boolean;
    //reloadSignup: () => void;
    reloadSignup: () => Promise<ISignup | null>;
    ssoError: string;
    initialising: boolean;
    disabled: boolean;
    licenceExpired: boolean;
    hasAccess: boolean;
    isSignupComplete: boolean;
    appInstanceApiUrl: string;
    licenceExpiry: string;
    t: (key: string, parameters?: any) => string; // TODO: IP13nParameters
    getReportPageUrl: (reportType: string) => string;
    getActionResultPageUrl: (actionType: number) => string;
}

export interface IPPAProviderProps {
    teamsUserCredential?: TeamsUserCredential;
    setInitialising: (initialising: boolean) => void;
    //setDisabled: (disabled: boolean) => void;
}

export const PPAContext = createContext<Partial<IPPAContext>>({});

export default function PPAProvider(props: PropsWithChildren<IPPAProviderProps>) {

    const [{ context }] = useTeams();
    const [signup, setSignup] = useState<ISignup | null>(null);
    const [tenant, setTenant] = useState<ITenant | null>(null);
    //const [loadedSignup, setLoadedSignup] = useState<boolean>(false);
    const [reloadSignup, setReloadSignup] = useState<string>();

    const [ssoError, setSsoError] = useState<string>("");

    // setInitialising
    const [isInitialising, setIsInitialising] = useState<boolean>(true);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [isLicenceExpired, setIsLicenceExpired] = useState<boolean>(false);
    const [hasAccess, setHasAccess] = useState<boolean>(false);
    const [isSignupComplete, setIsSignupComplete] = useState<boolean>(false);
    const [appInstanceApiUrl, setAppInstanceApiUrl] = useState<string>("");
    const [licenceExpiry, setLicenceExpiry] = useState<string>("");

    const setInitialising = (initialising: boolean) => {
        setIsInitialising(initialising);
        props.setInitialising(initialising);
    }

    // Create the api client for each of the api calls.
    const getApiClient = (apiUrl: string): AxiosInstance => {
        return createApiClient(
            apiUrl,
            // TODO: Put the try catch around the async call??
            new BearerTokenAuthProvider(async () => {
                try {
                    const accessToken = await props.teamsUserCredential?.getToken("");
                    setSsoError("");
                    return accessToken ? accessToken.token : "";
                }
                catch (error: unknown) {
                    console.log(`PPAProvider -> getApiClient ->`, error);
                    const typedError = error as any;

                    if (typedError.message) {
                        console.log(`error.message ->`, typedError.message);
                        setSsoError(typedError.message);
                    }
                    else {
                        setSsoError(JSON.stringify(error));
                    }

                    //throw error;
                    return "";
                }
            })
        );
    };

    const getSignup = async (): Promise<ISignup | null> => {
        try {
            console.log(`PPAProvider -> getSignup`);
            const response = await getApiClient(config.apiUrl || "").get<ISignup>(`/api/signups/${context?.user?.tenant?.id}`);

            console.log(`PPAProvider -> getSignup ->`, response);
            setSignup(response.data);
            //setLoadedSignup(true);

            return response.data;
        }
        catch (ex: unknown | AxiosError<string>) {
            console.log(`PPAProvider -> getSignup -> error ->`, ex);
            //setLoadedSignup(true);
            if (axios.isAxiosError(ex)) {
                console.log(`Index -> getSignup error -> Axios Error`, ex.code, ex.response?.status);
                //   setLoadErrorCode(ex.code);
                if (ex.response?.status === 404) {
                    console.log(`Index -> getSignup error -> 404`);

                }
                else {
                    // TODO: Initialising error...
                }
            }

            setInitialising(false);
            return null;
        }
    }

    useEffect(() => {
        if (props.teamsUserCredential && context?.user?.tenant) {
            try {
                //console.log(`PPAProvider -> useEffect -> context ->`, context);
                getSignup();
            }
            catch (ex: unknown | AxiosError<string>) {
                console.log(`PPAProvider -> useEffect -> getSignup -> error ->`, ex);
            }
        }
    }, [props.teamsUserCredential, context?.user?.tenant, reloadSignup]);

    useEffect(() => {
        if (signup) {
            const getTenant = async () => {
                try {
                    console.log(`PPAProvider -> useEffect[signup] -> getTenant`);
                    const response = await getApiClient(signup.appInstance.apiUrl).get<ITenant>(`/api/tenants/${signup.m365TenantId}`);
                    console.log(`PPAProvider -> useEffect[signup] -> getTenant ->`, response);
                    setTenant(response.data);
                    setInitialising(false);

                    //setSsoError("Temp error...");
                }
                catch (ex: unknown | AxiosError<string>) {
                    console.log(`PPAProvider -> useEffect[signup] -> getTenant -> error ->`, ex);

                    // TODO: Initialising Error
                    setInitialising(false);
                }
            }
            if (signup.enabled) {
                getTenant();
            }
            else {
                console.log(`PPAContext -> usEffect[signup] -> signup disabled ->`, signup);
                setInitialising(false);
            }
        }
    }, [signup]);

    // TODO: useEffect to set hasAccess and inc signup.isSignupComplete...

    useEffect(() => {
        console.log(`PPAContext -> usEffect[signup, tenant] ->`, signup, tenant);
        if (signup) {
            setIsDisabled(!signup.enabled);
            const expiry = parseISO(signup.licenceAbsoluteExpiry);
            setIsLicenceExpired(differenceInSeconds(expiry, new Date()) < 0);
            setLicenceExpiry(signup.licenceAbsoluteExpiry);
            setIsSignupComplete(signup.isSignupComplete);
            if (signup.appInstance) {
                setAppInstanceApiUrl(signup.appInstance.apiUrl);
            }
        }
        else {

        }
    }, [signup, tenant]);

    useEffect(() => {
        setHasAccess(!isDisabled && !isLicenceExpired);
    }, [isDisabled, isLicenceExpired]);

    const translate = (key: string, parameters?: any): string => {

        var stringValue = key;
        // TEMP: Until the keys and values come from an api call so they can be used in here.
        switch (key) {
            case 'StorageInTenantReport':
                stringValue = 'Storage in tenant report';
                break;
            case 'StorageInWorkspaceReport':
                stringValue = 'Storage in workspace report';
                break;
            case 'GuestUsersInTenantReport':
                stringValue = 'Guest users in tenant report';
                break;
            case 'GuestUsersInWorkspaceReport':
                stringValue = 'Guest users in workspace report';
                break;
            case 'GuestUserReport':
                stringValue = 'Guest user report';
                break;
            case 'SharingLinksInTenantReport':
                stringValue = 'Sharing links in tenant report';
                break;
            case 'SharingLinksInWorkspaceReport':
                stringValue = 'Sharing links in workspace report';
                break;
            case 'OrphanedUsersInTenantReport':
                stringValue = 'Orphaned users in tenant report';
                break;
            case 'OrphanedMailboxesInTenantReport':
                stringValue = 'Orphaned mailboxes in tenant report';
                break;
            case 'actionType0Name':
                stringValue = 'Action zero';
                break;
            case 'actionType1Name':
                stringValue = 'Remove orphaned users';
                break;
            case 'reportRequestStatus0Label':
                stringValue = 'Pending';
                break;
            case 'reportRequestStatus1Label':
                stringValue = 'Processing';
                break;
            case 'reportRequestStatus2Label':
                stringValue = 'Saving';
                break;
            case 'reportRequestStatus3Label':
                stringValue = 'Complete';
                break;
            case 'reportRequestStatus4Label':
                stringValue = 'Failed';
                break;
            case 'actionRequestStatus0Label':
                stringValue = 'Pending';
                break;
            case 'actionRequestStatus1Label':
                stringValue = 'Processing';
                break;
            case 'actionRequestStatus2Label':
                stringValue = 'Saving';
                break;
            case 'actionRequestStatus3Label':
                stringValue = 'Complete';
                break;
            case 'actionRequestStatus4Label':
                stringValue = 'Failed';
                break;
            case 'loading':
                stringValue = 'Loading';
                break;
            case 'removeOrphanedUsersActionRequestMessageUserAndSite':
                stringValue = 'Remove {userDisplayName} as an orphaned user from the {siteTitle} workspace.';
                break;
            case 'removeOrphanedUsersActionRequestMessageUserOnly':
                stringValue = 'Remove {userDisplayName} as an orphaned user from all workspaces.';
                break;
            case 'removeOrphanedUsersActionRequestMessageSiteOnly':
                stringValue = 'Remove all orphaned users from the {siteTitle} workspace.';
                break;
            case 'removeOrphanedUsersActionNoResultsMessage':
                stringValue = 'No orphaned users were removed.';
                break;
            case 'sharePointSiteTemplateSPSMSITEHOST0Name':
                stringValue = 'Personalisation Site';
                break;
            case 'sharePointSiteTemplateSRCHCEN0Name':
                stringValue = 'Enterprise Search Center';
                break;
            default:
                break;
        }

        // TEMP: Copied from PP
        // Set the reg ex properties for tag checking.
        var anyTagRegExString = "{[a-zA-Z0-9]*}";
        var tagBracesRegExString = "[{}]";

        const anyTagRegEx = new RegExp(anyTagRegExString);
        const allTagsRegEx = new RegExp(anyTagRegExString, "g");

        const tagBracesReg = new RegExp(tagBracesRegExString, "g");

        if (parameters) {
            // Check if the string value has any tags in it.
            if (anyTagRegEx.test(stringValue)) {
                // Do a global match to get all the tags as an array.
                const allTags = stringValue.match(allTagsRegEx);

                // Loop through all the tags and transform them if found in the properties.
                allTags?.forEach((tag: string, idx: number) => {
                    // console.log(`tag ->`, tag);
                    // Strip the tag braces to get the property name.
                    var propertyName = tag.replace(tagBracesReg, "");

                    // Initialise the value to the tag so it remains unchanged if not matched.
                    var tagValue = tag;

                    // Check if the property name exists on the properties object provided.
                    if (parameters.hasOwnProperty(propertyName)) {

                        // Get the value. 
                        tagValue = parameters[propertyName];
                    }

                    // Replace the tag in the original string value with the value of the property.
                    stringValue = stringValue.replace(tag, tagValue);
                });
            }
        }

        return stringValue;
    }

    const getReportPageUrl = (reportType: string): string => {

        // TEMP: Until the reports come from an api call so they can be used in here.
        switch (reportType) {
            case 'StorageInTenantReport':
                return '/reports/storage/storage-tenant';
            case 'StorageInWorkspaceReport':
                return '/reports/storage/storage-workspace';
            case 'GuestUsersInTenantReport':
                return '/reports/guest-users/guest-users-tenant';
            case 'GuestUsersInWorkspaceReport':
                return '/reports/guest-users/guest-users-workspace';
            case 'GuestUserReport':
                return '/reports/guest-users/guest-users-user';
            case 'SharingLinksInTenantReport':
                return '/reports/sharing-links/sharing-links-tenant';
            case 'SharingLinksInWorkspaceReport':
                return '/reports/sharing-links/sharing-links-workspace';
            case 'OrphanedUsersInTenantReport':
                return '/reports/orphaned-objects/orphaned-users-tenant';
            case 'OrphanedMailboxesInTenantReport':
                return '/reports/orphaned-objects/orphaned-mailboxes-tenant';
            default:
                return '';
        }
    }

    const getActionResultPageUrl = (actionType: number): string => {
        switch (actionType) {
            case 1:
                return '/action-requests/remove-orphaned-users';
            default:
                return '';
        }
    }

    const tempWrapper: IPPAContext = {
        //signup: signup,
        //tenant: tenant,
        //loadedSignup: loadedSignup,
        //reloadSignup: () => { console.log(`reloadSignup -> `); setReloadSignup(new Date().toISOString()) },
        reloadSignup: getSignup,
        ssoError: ssoError,
        initialising: isInitialising,
        disabled: isDisabled,
        licenceExpired: isLicenceExpired,
        hasAccess: hasAccess,
        isSignupComplete: isSignupComplete,
        appInstanceApiUrl: appInstanceApiUrl,
        licenceExpiry: licenceExpiry,
        t: translate,
        getReportPageUrl: getReportPageUrl,
        getActionResultPageUrl: getActionResultPageUrl
    };

    return (
        <PPAContext.Provider value={tempWrapper}>{props.children}</PPAContext.Provider>
    )
}