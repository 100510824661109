import { useState } from "react";
import { ITableColumn } from "@interfaces/common.interfaces";
import ReportSection from "../../../reports-common/report-section/report-section";
import { IReportSectionProps } from "../../../reports-common/report-section/report-section.props";
import { IRequestModel } from "@interfaces/reports/reports-common.interfaces";
import { IOrphanedUsersInTenantReportDetails, IOrphanedUsersInTenantReportItem } from "@interfaces/reports/orphaned-object-reports.interfaces";
import OrphanedUsersInTenantReportSummary from "components/reports/orphaned-users/orphaned-users-in-tenant-report/orphaned-users-in-tenant-report-summary";
import CheckedCell from "components/reports-common/report-results-grid-cells/checked-cell/checked-cell";
import RemoveOrphanedUsersActionButton from "components/actions/orphaned-users/remove-orphaned-users-action/remove-orphaned-users-action-button";
import WorkspaceLinkCell from "components/reports-common/report-results-grid-cells/workspace-link-cell/workspace-link-cell";
//import RemoveOrphanedUsersActionInput from "components/actions/orphaned-users/remove-orphaned-users-action/remove-orphaned-users-action-input";
//import { IRemoveOrphanedUsersSelection, IRemoveOrphanedUsersActionRequest } from "@interfaces/actions/orphaned-user-actions.interfaces";


// TODO: Somewhere central. e.g. in the navitems????
const reportUrl: string = "/api/reports/orphaned-users-in-tenant";

// TODO: Each column defined somewhere central and put together here.
const cols: Array<ITableColumn<IOrphanedUsersInTenantReportItem>> = [
  {
    key: "workspaceName", label: "Workspace", showInGrid: true, showInExport: true, defaultWidth: 200,
    renderCell: (key: string, item: any) => (<WorkspaceLinkCell name={item.workspaceName} url={item.workspaceUrl} template={item.workspaceTemplate} type={item.workspaceType} />)
  },
  { key: "workspaceUrl", label: "Workspace url", showInGrid: false, showInExport: true },
  { key: "workspaceType", label: "Workspace type", showInGrid: false, showInExport: true },
  { key: "workspaceTemplate", label: "Workspace template", showInGrid: false, showInExport: true },
  { key: "displayName", label: "Display name", showInGrid: true, showInExport: true, minWidth: 100 },
  { key: "upn", label: "Upn", showInGrid: true, showInExport: true },
  { key: "email", label: "Email", showInGrid: false, showInExport: true },
  { key: "siteUserId", label: "Site user id", showInGrid: false, showInExport: true },
  { key: "aadObjectId", label: "Entra user id", showInGrid: true, showInExport: true },
  { key: "spLoginName", label: "SharePoint login name", showInGrid: false, showInExport: true },
  {
    key: "isSiteAdmin", label: "Is site admin", showInGrid: true, showInExport: true, minWidth: 100,
    renderCell: (key: string, item: IOrphanedUsersInTenantReportItem) => (<CheckedCell columnId={key} item={item} checkedValue={true} />),
    getExportValue: (key: string, item: IOrphanedUsersInTenantReportItem) => {
      return item.isSiteAdmin ? "Yes" : "No";
    }
  },
  { key: "deleted", label: "Deleted date", showInGrid: false, showInExport: true }
];

export default function OrphanedUsersInTenantReport() {

  const [model, setModel] = useState<IRequestModel>({});

  const isModelValid = (): boolean => {
    return true;
  }

  const reportSectionProps: IReportSectionProps<IOrphanedUsersInTenantReportItem, IOrphanedUsersInTenantReportDetails> = {
    columns: cols,
    summaryComponent: <OrphanedUsersInTenantReportSummary temp="the temp prop" />,
    reportUrl: reportUrl,
    model: model,
    isModelValid: isModelValid(),
    noItemsMessage: "No orphaned users found in the tenant.",
    exportFilenamePrefix: "OrphanedUsersInTenantReport",
    buttons: [
      {
        // order, etc...
        component: <RemoveOrphanedUsersActionButton />
      }
    ]
  }

  return (
    <ReportSection {...reportSectionProps}>
    </ReportSection>
  );
}
