import { PropsWithChildren, useContext } from "react";
import { Caption1, MessageBar, MessageBarBody, MessageBarTitle, Spinner, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { PPAContext } from "context/ppa-context";
import ScreenPlaceholder from "components/common/screen-placeholder/screen-placeholder";


export default function Disabled(props: any) {
    const ppaContext = useContext(PPAContext);

    return (
        <ScreenPlaceholder
            imageUrl="images/DisabledImage.png"
            title="No Access"
            message="ProvisionPoint Audit is disabled in your tenant" />
    );
}