import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { Caption1, MessageBar, MessageBarBody, MessageBarTitle, Spinner, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { PPAContext } from "context/ppa-context";
import ScreenPlaceholder from "components/common/screen-placeholder/screen-placeholder";
import { format, parseISO } from "date-fns";


export default function LicenceExpired(props: any) {
    const ppaContext = useContext(PPAContext);

    const [message, setMessage] = useState<string>("");

    useEffect(() => {
        if (ppaContext.licenceExpiry) {
            const expiry = parseISO(ppaContext.licenceExpiry);
            setMessage(`Your ProvisionPoint Audit licence expired on ${format(expiry, "PPp")}`);
        }
        else {
            setMessage(`You do not have a ProvisionPoint Audit licence.`);
        }
    }, []);

    return (
        <ScreenPlaceholder
            imageUrl="images/LicenceImage.png"
            title="Not Licensed"
            message={message} />
    );
}