// https://fluentsite.z22.web.core.windows.net/quick-start
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme
} from "@fluentui/react-components";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "context/teams-context";
import config from "lib/config";
import Error404 from "./errors/error-404";
import Index from "components/pages/index";
import ReportsWrapper from "./pages/reports/reports-wrapper";
import ReportsIndex from "./pages/reports/reports-index";
import ActionsWrapper from "./pages/actions/actions-wrapper";
import ActionsIndex from "./pages/actions/actions-index";
import UsersActionsIndex from "./pages/actions/users/users-actions-index";
import SharingLinksActionsIndex from "./pages/actions/sharing-links/sharing-links-actions-index";
import GuestUsersReportsIndex from "./pages/reports/guest-users/guest-users-reports-index";
import SharingLinksReportsIndex from "./pages/reports/sharing-links/sharing-links-reports-index";
import GuestUsersInWorkspaceReport from "./pages/reports/guest-users/guest-users-in-workspace-report";
import GuestUsersInTenantReport from "./pages/reports/guest-users/guest-users-in-tenant-report";
import GuestUserReport from "./pages/reports/guest-users/guest-user-report";
import PPAProvider, { PPAContext } from "context/ppa-context";
import SharingLinksInTenantReport from "./pages/reports/sharing-links/sharing-links-in-tenant-report";
import SharingLinksInWorkspaceReport from "./pages/reports/sharing-links/sharing-links-in-workspace-report";
import SignupIndex from "./pages/signup/signup-index";
import SignupWrapper from "./pages/signup/signup-wrapper";
import { useState } from "react";
import Initialising from "components/initialising-ppa/initialising";
import StorageReportsIndex from "./pages/reports/storage/storage-reports-index";
import StorageInWorkspaceReport from "./pages/reports/storage/storage-in-workspace-report";
import StorageInTenantReport from "./pages/reports/storage/storage-in-tenant-report";
import OrphanedObjectsReportsIndex from "./pages/reports/orphaned-objects/orphaned-objects-reports-index";
import OrphanedUsersInTenantReport from "./pages/reports/orphaned-objects/orphaned-users-in-tenant-report";
import ReportRequestsWrapper from "./pages/report-requests/report-requests-wrapper";
import ReportRequestsIndex from "./pages/report-requests/report-requests-index";
import ActionRequestsWrapper from "./pages/action-requests/action-requests-wrapper";
import ActionRequestsIndex from "./pages/action-requests/action-requests-index";
import OrphanedMailboxesInTenantReport from "./pages/reports/orphaned-objects/orphaned-mailboxes-in-tenant-report";
import RemoveOrphanedUsersAction from "./pages/action-requests/orphaned-objects/remove-orphaned-users-action";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function PPAApp() {
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  });

  const [initialisingPPA, setInitialisingPPA] = useState<boolean>(true);

  const setPPAInitialised = (initialising: boolean): void => {
    console.log(`PPAApp -> setPPAInitialised ->`, initialising);
    setInitialisingPPA(initialising);
  }

  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
      <PPAProvider
        teamsUserCredential={teamsUserCredential}
        setInitialising={setPPAInitialised}>
        <FluentProvider
          theme={
            themeString === "dark"
              ? teamsDarkTheme
              : themeString === "contrast"
                ? teamsHighContrastTheme
                : {
                  ...teamsLightTheme,
                  colorNeutralBackground3: "#eeeeee",
                }
          }
          style={{ height: '100%', background: 'transparent' }}>
          <Router>
            {initialisingPPA &&
              <Initialising />}
            {/* {!initialisingPPA && isDisabled &&
              <Disabled />} */}
            {!initialisingPPA &&
              <Routes>
                <Route path="/tab" element={<Navigate to={"/"} />}></Route>
                <Route path="/" element={<Index />}></Route>
                {/* <Route path="/signup" element={<SignupWrapper />}></Route> */}
                <Route path="/signup" element={<SignupWrapper />}>
                  <Route index element={<SignupIndex />} />
                  {/* <Route path="consent-redirect" element={<SignupConsentRedirect />} /> */}
                </Route>
                <Route path="reports" element={<ReportsWrapper />}>
                  <Route index element={<ReportsIndex />} />
                  <Route path="guest-users">
                    <Route index element={<GuestUsersReportsIndex />} />
                    <Route path="guest-users-tenant/:id?" element={<GuestUsersInTenantReport />} />
                    <Route path="guest-users-workspace/:id?" element={<GuestUsersInWorkspaceReport />} />
                    <Route path="guest-users-user/:id?" element={<GuestUserReport />} />
                  </Route>
                  <Route path="sharing-links">
                    <Route index element={<SharingLinksReportsIndex />} />
                    <Route path="sharing-links-tenant/:id?" element={<SharingLinksInTenantReport />} />
                    <Route path="sharing-links-workspace/:id?" element={<SharingLinksInWorkspaceReport />} />
                  </Route>
                  <Route path="storage">
                    <Route index element={<StorageReportsIndex />} />
                    <Route path="storage-tenant/:id?" element={<StorageInTenantReport />} />
                    <Route path="storage-workspace/:id?" element={<StorageInWorkspaceReport />} />
                  </Route>
                  <Route path="orphaned-objects">
                    <Route index element={<OrphanedObjectsReportsIndex />} />
                    <Route path="orphaned-users-tenant/:id?" element={<OrphanedUsersInTenantReport />} />
                    <Route path="orphaned-mailboxes-tenant/:id?" element={<OrphanedMailboxesInTenantReport />} />
                  </Route>
                </Route>
                <Route path="/report-requests" element={<ReportRequestsWrapper />}>
                  <Route index element={<ReportRequestsIndex />} />
                </Route>
                <Route path="actions" element={<ActionsWrapper />}>
                  <Route index element={<ActionsIndex />} />
                  <Route path="users" element={<UsersActionsIndex />} />
                  <Route path="sharing-links" element={<SharingLinksActionsIndex />} />
                </Route>
                <Route path="/action-requests" element={<ActionRequestsWrapper />}>
                  <Route index element={<ActionRequestsIndex />} />
                  <Route path="remove-orphaned-users/:id" element={<RemoveOrphanedUsersAction />} />
                </Route>
                <Route path="*" element={<Error404 />}></Route>
              </Routes>}
          </Router>
        </FluentProvider>
      </PPAProvider>
    </TeamsFxContext.Provider>
  );
}
