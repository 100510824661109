import {
    FluentIcon,
    QuestionCircleRegular,
    SendRegular,
    CheckmarkCircleRegular,
    ArrowClockwiseDashesRegular,
    SaveRegular,
    WarningRegular
} from "@fluentui/react-icons";


// public enum ReportRequestStatus
// {
//     Pending = 0,
//     Processing = 1,
//     Saving = 2,
//     Complete = 3,
//     Failed = 4
// }

export const getReportRequestStatusIcon = (id: number): FluentIcon => {
    switch (id) {
        case 0:
            return SendRegular;
        case 1:
            return ArrowClockwiseDashesRegular;
        case 2:
            return SaveRegular;
        case 3:
            return CheckmarkCircleRegular;
        case 4:
            return WarningRegular;
        default:
            return QuestionCircleRegular;
    }
};