import { PropsWithChildren, useEffect, useState } from "react";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";


const layoutStyles = makeStyles({
    mainWrapper: {
    }
});

export default function UnknownActionForm(props: any) {
    const styles = layoutStyles();

    return (
        <div className={styles.mainWrapper}>
            unknown
        </div>
    );
}