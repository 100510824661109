import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { Button, DrawerBody, DrawerHeader, DrawerHeaderTitle, InlineDrawer, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
    DismissRegular
} from "@fluentui/react-icons";
//import { IActionRequestsGridProps } from "./action-requests-grid.props";
import Grid from "components/grid/grid";
import { ITableColumn } from "@interfaces/common.interfaces";
import { IActionDefinition, IActionRequest } from "@interfaces/actions/actions-common.interfaces";
import NavigateCell from "components/reports-common/report-results-grid-cells/navigate-cell/navigate-cell";
import IconWithLabel from "components/reports-common/report-results-grid-cells/common/icon-with-label/icon-with-label";
import { getActionRequestStatusIcon } from "lib/action-requests";
import { PPAContext } from "context/ppa-context";
import PrincipalsCell from "components/reports-common/report-results-grid-cells/principals-cell/principals-cell";
import DateTimeCell from "components/reports-common/report-results-grid-cells/date-time-cell/date-time-cell";
import { IActionResultPanelProps } from "./action-result-panel.props";
import Action from "../action/action";
import { IRemoveOrphanedUsersSelection } from "@interfaces/actions/orphaned-user-actions.interfaces";
import RemoveOrphanedUsersActionForm from "components/actions/orphaned-users/remove-orphaned-users-action/remove-orphaned-users-action-form";
import RemoveOrphanedUsersActionResult from "components/actions/orphaned-users/remove-orphaned-users-action/remove-orphaned-users-action-result";
import { getActionTypeNameKey } from "lib/actions";

const layoutStyles = makeStyles({
    mainWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        //backgroundColor: tokens.colorNeutralBackground2
    },
    background: {
        backgroundColor: tokens.colorNeutralBackground2
    },
    contentWrapper: {
        ...shorthands.padding('1em', 0)
    },
    errorWrapper: {

    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        '> button': {
            ...shorthands.margin(0, 0, 0, '1em')
        }
    }
});

export default function ActionResultPanel(props: IActionResultPanelProps) {
    const styles = layoutStyles();

    const ppa = useContext(PPAContext);

    const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);

    useEffect(() => {
        setIsOpen(props.isOpen);
    }, [props.isOpen]);


    return (
        <>
            {props.actionRequest &&
                <InlineDrawer
                    className={styles.mainWrapper}
                    open={isOpen}
                    position="end"
                    size="medium"
                    separator>
                    <DrawerHeader className={styles.background}>
                        <DrawerHeaderTitle
                            action={
                                <Button
                                    appearance="subtle"
                                    aria-label="Close"
                                    icon={<DismissRegular />}
                                    onClick={props.onClose}
                                />
                            }>
                            {ppa.t ? ppa.t(getActionTypeNameKey(props.actionRequest.actionType)) : ''}
                        </DrawerHeaderTitle>
                    </DrawerHeader>
                    <DrawerBody className={styles.background}>
                        <Action actionRequestId={props.actionRequest.requestId} />
                    </DrawerBody>
                </InlineDrawer>}
        </>
    );
}