import { PropsWithChildren, useEffect, useState } from "react";
import { Label, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IStorageInTenantReportSummaryProps } from "./storage-in-tenant-report-summary.props";
import PercentageBar from "components/common/percentage-bar/percentage-bar";
import { getSizeLabel } from "lib/utils";

const layoutStyles = makeStyles({
    mainWrapper: {
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '1em'
    }
});

export default function StorageInTenantReportSummary(props: IStorageInTenantReportSummaryProps) {
    const styles = layoutStyles();

    const [additionalLabel, setAdditionalLabel] = useState<string>("");
    const [siteCountsLabel, setSiteCountsLabel] = useState<string>("");

    useEffect(() => {
        if (props.report?.details) {
            const used = getSizeLabel(props.report.details.sitesTotalSizeKB, 'KB');
            const max = getSizeLabel(props.report.details.storageQuotaMB, 'MB');
            setAdditionalLabel(`(${used} of ${max})`);

            setSiteCountsLabel(`${props.report.details.siteCount} site collections`);
        }

    }, [props.report?.details]);

    // <div>StorageInTenantReportDetails</div>
    // <div>{props.temp}</div>
    // <div>{props.report?.details?.storageQuotaMB}</div>
    // <div>{props.report?.details?.storageQuotaMBAllocated}</div>
    // <div>{props.report?.details?.sitesTotalSizeKB}</div>

    return (<div>
        {props.report?.details &&
            <>
                <div className={styles.section}>
                    <Label>% of quota</Label>
                    <PercentageBar
                        value={props.report.details.percentageOfQuota}
                        valueDecimalPlaces={2}
                        showLabel={true}
                        additionalLabel={additionalLabel} />
                </div>
                <div className={styles.section}>
                    <Label>Site count</Label>
                    <span>{siteCountsLabel}</span>
                </div>
            </>}
    </div>);
}