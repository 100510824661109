import { PropsWithChildren, createElement, useEffect, useState } from "react";
import {
    ArrowDownloadRegular,
    PersonDeleteRegular
} from "@fluentui/react-icons";
import { ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IOrphanedUsersInTenantReportItem } from "@interfaces/reports/orphaned-object-reports.interfaces";
import { IRemoveOrphanedUsersActionButtonProps } from "./remove-orphaned-users-action-button.props";
import { IRemoveOrphanedUsersSelection } from "@interfaces/actions/orphaned-user-actions.interfaces";
//import { IActionDefinition } from "@components/reports-common/report-section/report-section.props";
import RemoveOrphanedUsersActionForm from "./remove-orphaned-users-action-form";
import { IActionDefinition } from "@interfaces/actions/actions-common.interfaces";
import RemoveOrphanedUsersActionResult from "./remove-orphaned-users-action-result";
import { getActionFormComponent, getActionResultComponent } from "lib/actions";

const layoutStyles = makeStyles({
    mainWrapper: {
        //...shorthands.borderWidth('1px', '0'),
    }
});

export default function RemoveOrphanedUsersActionButton(props: IRemoveOrphanedUsersActionButtonProps) {
    const styles = layoutStyles();

    const [model, setModel] = useState<IRemoveOrphanedUsersSelection | undefined>();

    const onClick = () => {
        console.log(`RemoveOrphanedUsersActionButton -> onClick ->`);

        // Construct the action definition in here to return...
        // const actionDefinition: IActionDefinition<IRemoveOrphanedUsersSelection> = {
        //     model: model,
        //     form: <RemoveOrphanedUsersActionForm />,
        //     result: <RemoveOrphanedUsersActionResult />
        // };

        const actionDefinition: IActionDefinition<IRemoveOrphanedUsersSelection> = {
            actionType: 1,
            model: model,
            form: getActionFormComponent(1),
            result: getActionResultComponent(1)
        };


        if (props.onClick) {
            props.onClick(actionDefinition);
        }
    }

    useEffect(() => {
        console.log(`RemoveOrphanedUsersActionButton -> useEffect -> selectedItems ->`, props.selectedItems);

        if (props.selectedItems && props.selectedItems.length == 1) {
            const item = props.selectedItems[0];
            setModel({
                site: {
                    url: item.workspaceUrl,
                    name: item.workspaceName
                },
                user: {
                    upn: item.upn,
                    name: item.displayName
                }
            });
        }
        else {
            // Clear the model
            setModel(undefined);
        }
    }, [props.selectedItems]);

    return (
        <>
            {props.selectedItems && props.selectedItems.length > 0 &&
                <ToolbarButton
                    icon={<PersonDeleteRegular />}
                    disabled={props.selectedItems.length > 1}
                    onClick={onClick}>Remove</ToolbarButton>}
        </>
    );
}