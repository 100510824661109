import {
    FluentIcon,
    QuestionCircleRegular,
    EyeRegular,
    EditRegular,
    ArrowDownloadOffRegular,
    PersonFeedbackRegular
} from "@fluentui/react-icons";

// TODO: Really this returns the key and the consumer calls p13n.t on it.
export const getSharingLinkPermissionsTypePrimaryLabel = (id: number): string => {
    return t(`sharingLinkPermissionsType${id}PrimaryLabel`);
};

export const getSharingLinkPermissionsTypeSecondaryLabel = (id: number): string => {
    return t(`sharingLinkPermissionsType${id}SecondaryLabel`);
};

export const getSharingLinkPermissionsTypeIcon = (id: number): FluentIcon => {
    switch (id) {
        case 0:
            return EditRegular;
        case 1:
            return PersonFeedbackRegular;
        case 2:
            return EyeRegular;
        case 3:
            return ArrowDownloadOffRegular;
        default:
            return QuestionCircleRegular;
    }
};

// TEMP
const t = (key: string): string => {
    switch (key) {
        case `sharingLinkPermissionsType0PrimaryLabel`:
            return `Can edit`;
        case `sharingLinkPermissionsType1PrimaryLabel`:
            return `Can review`;
        case `sharingLinkPermissionsType2PrimaryLabel`:
            return `Can view`;
        case `sharingLinkPermissionsType3PrimaryLabel`:
            return `Can't download`;
        case `sharingLinkPermissionsType0SecondaryLabel`:
            return `Make any changes`;
        case `sharingLinkPermissionsType1SecondaryLabel`:
            return `Can only suggest changes`;
        case `sharingLinkPermissionsType2SecondaryLabel`:
            return `Can't make changes`;
        case `sharingLinkPermissionsType3SecondaryLabel`:
            return `Can view, but not downlaod`;
        default:
            return key;
    }
}