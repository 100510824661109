import { PropsWithChildren, useContext } from "react";
import { Button, Caption1, Card, CardHeader, Text, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
    InfoRegular,
    DismissRegular,
    TextBulletListRegular,
    ErrorCircleRegular,
    WarningRegular,
    CheckmarkSquareRegular,
    EmojiMehRegular
} from "@fluentui/react-icons";
import { PPAContext } from "context/ppa-context";
import { IServiceMessagesCardProps } from "./service-messages-card.props";
import { IServiceMessage } from "@interfaces/common.interfaces";
import { format, parseISO } from "date-fns";

const layoutStyles = makeStyles({
    mainWrapper: {
        ...shorthands.borderWidth('1px', '0'),
        ...shorthands.borderStyle('solid', 'none'),
        ...shorthands.borderColor(tokens.colorNeutralStrokeSubtle),
        ...shorthands.padding('0', '2em')
    },
    textWrapper: {
        ...shorthands.overflow('hidden'),
        '> span': {
            display: 'block',
            ...shorthands.overflow('hidden'),
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    },
    messageWrapper: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.margin(0, 0, '1em', 0),
        '> div > span': {
            display: 'block'
        }
    },
    iconColumn: {
        ...shorthands.margin(0, '5px', 0, 0)
    },
    textColumn: {}
});

export default function ServiceMessagesCard(props: IServiceMessagesCardProps) {
    const styles = layoutStyles();
    const ppa = useContext(PPAContext);

    const getIcon = (messageType: number): any => {
        switch (messageType) {
            case 1: {
                return <CheckmarkSquareRegular fontSize={20} />
            }
            case 2: {
                return <WarningRegular fontSize={20} />
            }
            case 3:
            case 7: {
                return <ErrorCircleRegular fontSize={20} />
            }
            default: {
                return <EmojiMehRegular fontSize={20} />
            }
        }
    }

    const getDate = (timeStamp: string): string => {
        const dateTime = parseISO(timeStamp);
        const formattedDate = isNaN(dateTime.getDate()) ? timeStamp : format(dateTime, 'PPp');
        return formattedDate;
    }

    return (
        <Card>
            <CardHeader
                image={<TextBulletListRegular fontSize={30} />}
                header={<div className={styles.textWrapper}><Text as="span" size={400}>{props.title}</Text></div>}
                action={<Button
                    appearance="transparent"
                    icon={<DismissRegular />}
                    aria-label="Back"
                    onClick={props.onClose} />} />
            <div>
                {props.messages.map((message, idx) => (
                    <div className={styles.messageWrapper} key={idx}>
                        <div className={styles.iconColumn}>{getIcon(message.type)}</div>
                        <div className={styles.textColumn}>
                            <Text as="span" size={200}>{getDate(message.timeStamp)}</Text>
                            <Text as="span" size={300}>{message.message}</Text>
                        </div>
                    </div>
                ))}
            </div>
        </Card>);
}