import { IReportRequest } from "@interfaces/reports/reports-common.interfaces";
import NavigationCards from "components/navigation/navigation-cards/navigation-cards";
import { getNavigationCardItems } from "lib/utils";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation, useSearchParams } from "react-router-dom";
import { TeamsFxContext } from "context/teams-context";
import { PPAContext } from "context/ppa-context";
import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
import Grid from "components/grid/grid";
import { ICommandBarItem, ITableColumn } from "@interfaces/common.interfaces";
import NavigateCell from "components/reports-common/report-results-grid-cells/navigate-cell/navigate-cell";
import PrincipalsCell from "components/reports-common/report-results-grid-cells/principals-cell/principals-cell";
import DateTimeCell from "components/reports-common/report-results-grid-cells/date-time-cell/date-time-cell";
import { makeStyles, shorthands } from "@fluentui/react-components";
import CommandBar from "components/command-bar/command-bar";
import PercentageBar from "components/common/percentage-bar/percentage-bar";
import IconWithLabel from "components/reports-common/report-results-grid-cells/common/icon-with-label/icon-with-label";
import { getReportRequestStatusIcon } from "lib/report-requests";
import {
    FilterRegular,
    ArrowImportRegular
} from "@fluentui/react-icons";
import { IActionRequest } from "@interfaces/actions/actions-common.interfaces";
import { getActionRequestStatusIcon } from "lib/action-requests";
import { IActionRequestsProps } from "./action-requests.props";
import ActionRequestsGrid from "../action-requests-grid/action-requests-grid";
import ActionResultPanel from "../action-result-panel/action-result-panel";
import { getActionTypeNameKey } from "lib/actions";


const defaultCommandBarItems: Array<ICommandBarItem> = [
    {
        id: 'openFilter',
        placement: 'right',
        type: 'button',
        order: 1,
        disabled: true,
        icon: <FilterRegular />
    },
    {
        id: 'openDetails',
        placement: 'right',
        type: 'button',
        order: 2,
        icon: <ArrowImportRegular />
    },
];

const layoutStyles = makeStyles({
    mainWrapper: {
        //...shorthands.borderWidth('1px', '0'),
    }
});


export default function ActionRequests(props: IActionRequestsProps) {
    const styles = layoutStyles();
    const location = useLocation();
    const [params] = useSearchParams();

    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    const ppa = useContext(PPAContext);

    const [actionRequests, setActionRequests] = useState<Array<IActionRequest>>([]);
    const [checkCount, setCheckCount] = useState<number>(0);

    const [selectedItems, setSelectedItems] = useState<Array<IActionRequest>>([]);

    const [isResultPanelOpen, setIsResultPanelOpen] = useState<boolean>(false);


    const onCloseResultPanel = () => {
        setIsResultPanelOpen(false);
    }


    const commandBarItems = useMemo(() => {
        //console.log(`ReportSection -> useMemo -> commandbar -> `);
        var buttons = defaultCommandBarItems.map(item => {
            //console.log(`ReportSection -> useMemo -> commandbar -> id -> `, item.id);
            switch (item.id) {
                case 'openDetails':
                    return {
                        ...item,
                        hidden: selectedItems.length !== 1,
                        onClick: () => {
                            console.log(`openDetails -> onClick ->`);
                            setIsResultPanelOpen(!isResultPanelOpen);
                        }
                    }
                default:
                    return item;
            }
        });

        // if (props.buttons) {
        //     var actionButtons = props.buttons.map<ICommandBarItem>((button): ICommandBarItem => {
        //         return {
        //             id: '1',
        //             placement: 'left',
        //             type: 'component',
        //             order: 10,
        //             component: cloneElement(button.component, { selectedItems: selectedItems, onClick: onActionButtonClick })
        //         }
        //     });

        //     buttons = buttons.concat(actionButtons);
        // }

        return buttons;
    }, [isResultPanelOpen, actionRequests, selectedItems]);


    // TODO: openDetails onClick -> 
    // call util to get the IActionDefinition and set in the state.
    // then set as open.


    // Create the api client for each of the api calls.
    const apiClient = useMemo(() => {
        return createApiClient(
            ppa.appInstanceApiUrl || "",
            new BearerTokenAuthProvider(async () => (await teamsUserCredential?.getToken(""))!.token)
        );
    }, [ppa.appInstanceApiUrl]);

    const getActionRequests = async (): Promise<Array<any>> => {
        try {
            const response = await apiClient.get<Array<IActionRequest>>(`/api/action-requests`);

            const temp = response.data.map((item: IActionRequest) => {
                // TODO: Remove this when the ppa getReportPageUrl method is no longer optional!!
                const url = ppa.getActionResultPageUrl ? ppa.getActionResultPageUrl(item.actionType) : '/';
                // const reportName = ppa.t ? ppa.t(item.reportType) : item.reportType;

                const actionKeyName = getActionTypeNameKey(item.actionType);
                //const url = ppa.getActionPageUrl ? ppa.getActionPageUrl(item.actionType) : '/';
                const actionName = ppa.t ? ppa.t(actionKeyName) : actionKeyName;
                //const url = ``;

                return {
                    ...item,
                    actionRequestUrl: `${url}/${item.requestId}`,
                    actionName: actionName
                };
            });

            setActionRequests(temp);
            setCheckCount(checkCount + 1);
            return temp;
        }
        catch (ex: unknown) {
            throw ex; // TODO: Handle the error, don't throw as this is the top level!!
        }
    }

    useEffect(() => {
        getActionRequests();
    }, []);

    useEffect(() => {
        // 
        var timerId: NodeJS.Timeout;
        const hasProcessingRequests: boolean = actionRequests.filter(r => r.status !== 3 && r.status !== 4).length > 0;
        const timeOut = hasProcessingRequests ? 2000 : 10000;

        timerId = setTimeout(() => {
            getActionRequests();
        }, timeOut);

        return () => clearTimeout(timerId);
    }, [checkCount]);


    return (
        <>
            <CommandBar items={commandBarItems} />
            <section className={styles.mainWrapper} style={{ flexGrow: 1, display: 'flex', overflow: 'hidden', position: 'relative' }}>
                <ActionRequestsGrid
                    items={actionRequests}
                    onSelectionChanged={(items: Array<IActionRequest>) => { setSelectedItems(items) }} />
                <ActionResultPanel
                    isOpen={isResultPanelOpen}
                    onClose={onCloseResultPanel}
                    actionRequest={selectedItems[0]} />
            </section>
        </>
    );
}