import { ChangeEvent, ChangeEventHandler, PropsWithChildren, useContext, useEffect, useState } from "react";
import {
    ArrowDownloadRegular,
    PersonDeleteRegular
} from "@fluentui/react-icons";
import { Input, Label, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IRemoveOrphanedUsersActionRequest, IRemoveOrphanedUsersSelection } from "@interfaces/actions/orphaned-user-actions.interfaces";
import { IRemoveOrphanedUsersActionFormProps } from "./remove-orphaned-users-action-form.props";
import { url } from "inspector";
import { unzip } from "zlib";
import { IUserSelection, IWorkspaceSelection } from "@interfaces/actions/actions-common.interfaces";
import WorkspaceCard from "components/common/workspace-card/workspace-card";
import PrincipalCard from "components/common/principal-card/principal-card";
import ActionFormButtons from "components/actions-common/action-buttons/action-form-buttons";
import ActionFormRequestMessage from "components/actions-common/action-form-request-message/action-form-request-message";
import { PPAContext } from "context/ppa-context";

const layoutStyles = makeStyles({
    mainWrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    itemWrapper: {
        ...shorthands.margin('1em', 0, 0, 0)
    }
});


const apiUrl: string = "/api/actions/remove-orphaned-users";

// TODO: Change this to be ...Form
export default function RemoveOrphanedUsersActionForm(props: IRemoveOrphanedUsersActionFormProps) {
    const styles = layoutStyles();
    const ppa = useContext(PPAContext);

    const [isValid, setIsValid] = useState<boolean>(false);

    const [selection, setSelection] = useState<IRemoveOrphanedUsersSelection>();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [canRemoveWorkspace, setCanRemoveWorkspace] = useState<boolean>(true);
    const [canRemovePrincipal, setCanRemovePrincipal] = useState<boolean>(true);

    const [requestMessage, setRequestMessage] = useState<string>(``);

    // TODO: Maybe put this in a base class and only override if needed here...
    const onSubmitHandler = async (): Promise<any> => {
        if (!props.onSubmit) {
            return Promise.reject();
        }

        // TODO: Only actually submit if valid...

        const requestModel: IRemoveOrphanedUsersActionRequest = {};

        if (selection?.site)
            requestModel.workspace = { siteUrl: selection.site.url };
        if (selection?.user)
            requestModel.user = { upn: selection.user.upn };

        setIsSubmitting(true);

        return props.onSubmit("/api/actions/remove-orphaned-users", requestModel).then(
            (data: any) => {
                console.log(`RemoveOrphanedUsersActionInput -> onSubmitHandler -> success ->`, data);
                setIsSubmitting(false);
                return data;
            },
            (error: any) => {
                console.log(`RemoveOrphanedUsersActionInput -> onSubmitHandler -> error ->`, error);
                setIsSubmitting(false);
                return Promise.reject(error);
            });
    }

    //
    useEffect(() => {
        const selection: IRemoveOrphanedUsersSelection = {};
        if (props.model) {
            if (props.model.site) {
                selection.site = {
                    url: props.model.site.url,
                    name: props.model.site.name
                };
            }
            if (props.model.user) {
                selection.user = {
                    upn: props.model.user.upn,
                    name: props.model.user.name
                };
            }
        }
        setSelection(selection);
    }, [props.model]);

    //
    useEffect(() => {
        if (isSubmitting) {
            setCanRemoveWorkspace(false);
            setCanRemovePrincipal(false);
        }
        else {
            setCanRemovePrincipal(!selection?.site === false);
            setCanRemoveWorkspace(!selection?.user === false);

            // Set the request message based on the selection.
            var requestMessageKey = "";

            const params = {
                userDisplayName: selection?.user?.name,
                siteTitle: selection?.site?.name
            };

            if (selection?.site && selection?.user) {
                requestMessageKey = "removeOrphanedUsersActionRequestMessageUserAndSite";
            }
            else if (selection?.user) {
                requestMessageKey = "removeOrphanedUsersActionRequestMessageUserOnly";
            }
            else if (selection?.site) {
                requestMessageKey = "removeOrphanedUsersActionRequestMessageSiteOnly";
            }

            setRequestMessage(ppa.t ? ppa.t(requestMessageKey, params) : '');
        }
    }, [isSubmitting, selection]);

    //
    const removePrincipal = (): void => {
        setSelection({
            ...selection,
            user: undefined
        });
    }

    //
    const removeWorkspace = (): void => {
        setSelection({
            ...selection,
            site: undefined
        });
    }

    return (
        <div className={styles.mainWrapper}>
            {selection?.user &&
                <div className={styles.itemWrapper}>
                    <Label>Orphaned user</Label>
                    <PrincipalCard
                        item={{ upn: selection.user.upn, displayName: selection.user.name }}
                        onDismissClick={canRemovePrincipal ? removePrincipal : undefined} />
                </div>}
            {selection?.site &&
                <div className={styles.itemWrapper}>
                    <Label>Workspace</Label>
                    <WorkspaceCard
                        item={{ title: selection.site.name, siteUrl: selection.site.url }}
                        onDismissClick={canRemoveWorkspace ? removeWorkspace : undefined} />
                </div>}
            <ActionFormRequestMessage
                icon={<PersonDeleteRegular />}
                message={requestMessage} />
            <ActionFormButtons
                onSubmit={onSubmitHandler}
                onCancel={props.onCancel} />
        </div>
    );
}