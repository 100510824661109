import {
    OpenRegular,
    NotepadPersonRegular,
    GuestRegular,
    GlobePersonRegular,
    PersonRegular,
    StorageRegular,
    DocumentOnePageLinkRegular,
    ShareRegular,
    FolderGlobeRegular,
    DocumentDatabaseRegular,
    HomeDatabaseRegular,
    DatabaseRegular,
    PersonQuestionMarkRegular,
    DocumentSearchRegular,
    MailProhibitedRegular
} from "@fluentui/react-icons";
import { INavItemV3 } from "@interfaces/common.interfaces";


const navItems: Array<INavItemV3> = [
    {
        id: "1",
        route: "/reports",
        label: "Reports",
        description: "These are the reports available to run in your tenant.",
        icon: DocumentSearchRegular,
        //isReport: false,
        //enabled: true
        onTopNav: true
    },
    {
        id: "1.1",
        route: "/reports/guest-users",
        label: "Guest users reports",
        description: "These reports will identify the guest users in your tenant and the areas they have access to.",
        icon: GuestRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1"
    },
    {
        id: "1.1.1",
        route: "/reports/guest-users/guest-users-tenant",
        label: "Tenant wide guest users report",
        description: "Reports on all the guest users across the M365 tenant and the role they have been assigned.",
        icon: GlobePersonRegular,
        //isReport: true,
        //enabled: true,
        parentId: "1.1"
    },
    {
        id: "1.1.2",
        route: "/reports/guest-users/guest-users-workspace",
        label: "Guest users in workspace report",
        description: "Reports on the guest users in a specific workspace and the role they have been assigned.",
        icon: NotepadPersonRegular,
        //isReport: true,
        //enabled: true,
        parentId: "1.1"
    },
    {
        id: "1.1.3",
        route: "/reports/guest-users/guest-users-user",
        label: "Specific guest user report",
        description: "Reports on the permissions assigned to a specific guest user across all the workspaces in the M365 tenant.",
        icon: PersonRegular,
        //isReport: true,
        //enabled: true,
        parentId: "1.1"
    },
    {
        id: "1.2",
        route: "/reports/sharing-links",
        label: "Sharing links reports",
        description: "The reports for sharing links in the tenant.",
        icon: OpenRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1",
        disabled: false
    },
    {
        id: "1.2.1",
        route: "/reports/sharing-links/sharing-links-workspace",
        label: "Sharing links in workspace report",
        description: "Reports on the sharing links in a specific workspace and who they have been shared with.",
        icon: DocumentOnePageLinkRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1.2",
        disabled: false
    },
    {
        id: "1.2.2",
        route: "/reports/sharing-links/sharing-links-tenant",
        label: "Tenant wide sharing links report",
        description: "Reports on all the sharing links across the M365 tenant.",
        icon: ShareRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1.2",
        disabled: false
    },
    {
        id: "1.3",
        route: "/reports/storage",
        label: "Storage reports",
        description: "The reports on storage capacity and usage in the tenant.",
        icon: DatabaseRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1",
        disabled: false
    },
    {
        id: "1.3.1",
        route: "/reports/storage/storage-workspace",
        label: "Storage in workspace report",
        description: "Reports on the storage capacity and usage in a specific workspace.",
        icon: DocumentDatabaseRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1.3",
        disabled: false
    },
    {
        id: "1.3.2",
        route: "/reports/storage/storage-tenant",
        label: "Tenant wide storage report",
        description: "Reports on the storage capacity and usage across the M365 tenant.",
        icon: HomeDatabaseRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1.3",
        disabled: false
    },
    {
        id: "1.4",
        route: "/reports/orphaned-objects",
        label: "Orphaned object reports",
        description: "The reports on orphaned objects across the tenant.",
        icon: PersonQuestionMarkRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1",
        disabled: false
    },
    {
        id: "1.4.1",
        route: "/reports/orphaned-objects/orphaned-users-tenant",
        label: "Tenant wide orphaned users report",
        description: "Reports on the orphaned users in SharePoint sites across the M365 tenant.",
        icon: PersonQuestionMarkRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1.4",
        disabled: false
    },
    {
        id: "1.4.2",
        route: "/reports/orphaned-objects/orphaned-mailboxes-tenant",
        label: "Tenant wide orphaned mailboxes report",
        description: "Reports on the orphaned mailboxes in Exchange across the M365 tenant.<br/>An orhpaned mailbox is a mailbox that is assigned to a single user and the user is disabled in Entra ID.",
        icon: MailProhibitedRegular,
        //isReport: false,
        //enabled: true,
        parentId: "1.4",
        disabled: false
    },
    {
        id: "2",
        route: "/actions",
        label: "Actions",
        description: "You can perform certain actions.",
        //showAsCard: true
        //onTopNav: true
        disabled: true
    },
    {
        id: "3",
        route: "/report-requests",
        label: "Report requests",
        //onTopNav: true
        disabled: true
    },
    {
        id: "4",
        route: "/about",
        label: "About",
        //onTopNav: true
        disabled: true
    }
]

export default navItems;