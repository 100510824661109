import { PropsWithChildren, useEffect, useState } from "react";
import { Input, InputProps, Label, Toolbar, ToolbarButton, makeStyles, shorthands, tokens, useId } from "@fluentui/react-components";
import {
    InfoRegular
} from "@fluentui/react-icons";
import { IWorkspacePickerProps } from "./workspace-picker.props";
import WorkspacePickerInput from "./workspace-picker-input";
//import WorkspacePickerResults from "./workspace-picker-results";
//import WorkspacePickerItem from "./workspace-picker-item";
import { IWorkspace } from "@interfaces/common.interfaces";
import WorkspaceCard from "components/common/workspace-card/workspace-card";

const layoutStyles = makeStyles({
    mainWrapper: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap("2px"),
        marginBottom: '1em'
    }
});

// TODO:
//// Show the selected workspace on re-open panel.
// Style the wrapper
//// Style the input
// Style the dropdown list
// Show a searching progress bar in the dropdown.
//// Style the result item
//// Style the selected item
//// Call the api to get the results
//// Style the go and cancel buttons nicer
//// Validation error? if no site selected?
// Find a better graph/csom call to get sites...
// Input panel readonly while processing the report.
// Result item a button so it can have focus and be tabbed to


export default function WorkspacePicker(props: IWorkspacePickerProps) {
    const styles = layoutStyles();

    const inputId = useId();
    const [searchText, setSearchText] = useState<string>();
    const [selectedWorkspace, setSelectedWorkspace] = useState<IWorkspace | undefined>(props.model);
    const [searchResults, setSearchResults] = useState<Array<IWorkspace>>([]);


    const onInputChange = (value?: string) => {
        console.log(`WorkspacePicker -> onInputChange -> `, value);
    }

    const onResultsChange = (results: Array<IWorkspace>) => {
        console.log(`WorkspacePicker -> onResultsChange -> `, results);
        setSearchResults(results);
    }

    const onSelectWorkspace = (workspace: IWorkspace) => {
        console.log(`WorkspacePicker -> onSelectWorkspace -> `, workspace);
        setSelectedWorkspace(workspace);
    }

    const onClearSelection = (workspace: IWorkspace) => {
        console.log(`WorkspacePicker -> onClearSelection -> `, workspace);
        setSelectedWorkspace(undefined);
    }

    useEffect(() => {
        props.onChange(selectedWorkspace);
    }, [selectedWorkspace]);


    return (
        <div className={styles.mainWrapper}>
            <Label htmlFor={inputId}>Workspace</Label>
            {/* {selectedWorkspace && <WorkspacePickerItem item={selectedWorkspace} onActionClick={onClearSelection} />} */}
            {selectedWorkspace && <WorkspaceCard item={selectedWorkspace} onDismissClick={onClearSelection} />}
            <WorkspacePickerInput
                inputId={inputId}
                onChange={onInputChange}
                onResultsChange={onResultsChange}
                onSelectWorkspace={onSelectWorkspace}
                visible={selectedWorkspace ? false : true} />
        </div>
    );
}