import { Label, makeStyles, shorthands, useId } from "@fluentui/react-components";
import { IPrincipalPickerProps } from "./principal-picker.props";
import { useEffect, useState } from "react";
import { IPrincipal } from "@interfaces/common.interfaces";
//import PrincipalPickerItem from "./principal-picker-item";
import PrincipalPickerInput from "./principal-picker-input";
import PrincipalCard from "components/common/principal-card/principal-card";



const layoutStyles = makeStyles({
    mainWrapper: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap("2px"),
        marginBottom: '1em'
    }
});


export default function PrincipalPicker(props: IPrincipalPickerProps) {
    const styles = layoutStyles();

    const inputId = useId();
    const [searchText, setSearchText] = useState<string>();
    const [selectedPrincipal, setSelectedPrincipal] = useState<IPrincipal | undefined>(props.model);
    const [searchResults, setSearchResults] = useState<Array<IPrincipal>>([]);

    const onInputChange = (value?: string) => {
        console.log(`PrincipalPicker -> onInputChange -> `, value);
    }

    const onResultsChange = (results: Array<IPrincipal>) => {
        console.log(`PrincipalPicker -> onResultsChange -> `, results);
        setSearchResults(results);
    }

    const onSelectPrincipal = (user: IPrincipal) => {
        console.log(`PrincipalPicker -> onSelectPrincipal -> `, user);
        setSelectedPrincipal(user);
    }

    const onClearSelection = (user: IPrincipal) => {
        console.log(`PrincipalPicker -> onClearSelection -> `, user);
        setSelectedPrincipal(undefined);
    }

    useEffect(() => {
        props.onChange(selectedPrincipal);
    }, [selectedPrincipal]);

    return (
        <div className={styles.mainWrapper}>
            <Label htmlFor={inputId}>User/Group</Label>
            {/* {selectedPrincipal && <PrincipalPickerItem item={selectedPrincipal} onActionClick={onClearSelection} />} */}
            {selectedPrincipal && <PrincipalCard item={selectedPrincipal} onDismissClick={onClearSelection} />}
            <PrincipalPickerInput
                inputId={inputId}
                onChange={onInputChange}
                onResultsChange={onResultsChange}
                onSelectPrincipal={onSelectPrincipal}
                visible={selectedPrincipal ? false : true}
                principalTypes={props.principalTypes}
                userType={props.userType} />
        </div>
    );
}