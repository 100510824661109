import { PropsWithChildren, useContext, useEffect } from "react";
import { ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { ISignupActionsProps } from "./signup-actions.props";
import { ISignup, ISignupAction } from "@interfaces/signup.interfaces";
import SignupAction from "./signup-action";
import { ISignupCompleteProps } from "./signup-complete.props";
import { IScreenPlaceholderProps } from "components/common/screen-placeholder/screen-placeholder.props";
import ScreenPlaceholder from "components/common/screen-placeholder/screen-placeholder";
import { useNavigate } from "react-router-dom";
import { PPAContext } from "context/ppa-context";


export default function SignupComplete(props: ISignupCompleteProps) {

    const ppaContext = useContext(PPAContext);
    const navigate = useNavigate();

    useEffect(() => {
        console.log(`SignupComplete -> useEffect ->`);

        setTimeout(() => {
            console.log(`SignupComplete -> useEffect -> timeout ->`);
            if (ppaContext.reloadSignup) {
                ppaContext.reloadSignup().then(
                    (signup: ISignup | null) => {
                        console.log(`SignupComplete -> ppaContext.reloadSignup -> success ->`, signup);
                        if (signup?.isSignupComplete) {
                            navigate(`/`);
                        }
                    },
                    (error: any) => {
                        console.log(`SignupComplete -> ppaContext.reloadSignup -> error ->`, error);
                    }
                ).catch(
                    (exception: any) => {
                        console.log(`SignupComplete -> ppaContext.reloadSignup -> catch ->`, exception);
                    }
                );
            }
        }, 2000);
    }, []);

    const placeholderProps: IScreenPlaceholderProps = {
        imageUrl: "images/SignupCompleteImage.png",
        title: "Signup complete",
        message: "Signup to ProvisionPoint Audit is complete. You will be redirected to the app shortly."
    }

    return (
        <div>
            <ScreenPlaceholder {...placeholderProps} />
        </div>
    );
}