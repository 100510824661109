import { useEffect, useState } from "react";
import { Field, ProgressBar, makeStyles, shorthands } from "@fluentui/react-components";
import { IReportProgressProps } from "./report-progress.props";

const layoutStyles = makeStyles({
    mainWrapper: {
        ...shorthands.margin('5em')
    }
});

// Interface for setting the progress only used in ths component.
interface IProgress {
    label: string;
    value: number | undefined;
    state: "none" | "success" | "error";
    //percentageComplete: number | undefined;
}

export default function ReportProgress(props: IReportProgressProps) {
    const styles = layoutStyles();
    const [progress, setProgress] = useState<IProgress>();

    // TEMP
    const pending = "Pending...";
    const processing = "Processing...";
    const saving = "Saving...";
    const complete = "Complete";
    const failed = "Failed";


    useEffect(() => {
        let label: string = "";
        let value: number | undefined = 0;
        let state: "none" | "success" | "error" = "none";
        //let percentageComplete: number | undefined = undefined;
        switch (props.status) {
            case -1:
                break;
            case 0:
                label = pending;
                break;
            case 1:
                value = props.percentageComplete === undefined ? undefined : props.percentageComplete / 100;
                label = processing;
                break;
            case 2:
                value = undefined;
                label = saving;
                break;
            case 3:
                value = 1;
                label = complete;
                state = "success";
                break;
            case 4:
                value = 1;
                label = failed;
                state = "error";
                break;
        }
        setProgress({
            label: label,
            value: value,
            state: state,
            //percentageComplete: percentageComplete
        });
    }, [props.status, props.percentageComplete]);

    return (
        <div className={styles.mainWrapper}>
            {progress &&
                <Field validationMessage={progress.label} validationState={progress.state}>
                    <ProgressBar value={progress.value} />
                </Field>}
        </div>
    );
}