//import navItems from "lib/nav-items";
import ReportProgress from "components/reports-common/report-progress/report-progress";
//import NavigationCards from "../../../navigation/navigation-cards/navigation-cards";
import { useContext, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { format, isDate, parse, parseISO, toDate } from 'date-fns';
import DateTimeCell from "components/reports-common/report-results-grid-cells/date-time-cell/date-time-cell";
import { IRequestModel } from "@interfaces/reports/reports-common.interfaces";
import { ITableColumn, IWorkspace } from "@interfaces/common.interfaces";
import { IReportSectionProps } from "components/reports-common/report-section/report-section.props";
import ReportSection from "components/reports-common/report-section/report-section";
import WorkspacePicker from "components/controls/workspace-picker/workspace-picker";
import CheckedCell from "components/reports-common/report-results-grid-cells/checked-cell/checked-cell";
import { ISharedLinksInTenantReportDetails, ISharedLinksInTenantReportItem } from "@interfaces/reports/shared-link-reports.interfaces";
import LinkCell from "components/reports-common/report-results-grid-cells/link-cell/link-cell";
import PrincipalsCell from "components/reports-common/report-results-grid-cells/principals-cell/principals-cell";
import { escapeForExport } from "lib/utils";
import { getSharingLinkPermissionsTypePrimaryLabel, getSharingLinkPermissionsTypeSecondaryLabel } from "lib/shared-links";
import SharingLinkPermissionsTypeCell from "components/reports-common/report-results-grid-cells/shared-links/sharing-link-permissions-type-cell/sharing-link-permissions-type-cell";
import InvitationsCell from "components/reports-common/report-results-grid-cells/intivations-cell/invitations-cell";


// TODO: Somewhere central. e.g. in the navitems????
const reportUrl: string = "/api/reports/sharing-links-in-tenant";

// TODO: Each column defined somewhere central and put together here.
const cols: Array<ITableColumn<ISharedLinksInTenantReportItem>> = [ // TODO: 
  {
    key: "workspaceName", label: "Workspace", showInGrid: true, showInExport: true, defaultWidth: 200,
    renderCell: (key: string, item: any) => (<LinkCell label={item.workspaceName} url={item.workspaceUrl} />)
  },
  { key: "workspaceUrl", label: "Workspace url", showInGrid: false, showInExport: true },
  {
    key: "itemName", label: "Item", showInGrid: true, showInExport: true, defaultWidth: 200,
    renderCell: (key: string, item: ISharedLinksInTenantReportItem) => (<LinkCell label={item.itemName} url={item.itemUrl} />)
  },
  { key: "itemUrl", label: "Item url", showInGrid: false, showInExport: true, minWidth: 100 },
  { key: "linkUrl", label: "Link url", showInGrid: false, showInExport: true, minWidth: 100, idealWidth: 300 },
  { key: "itemUniqueId", label: "Item unique id", showInGrid: false, showInExport: true, minWidth: 100 },
  { key: "shareId", label: "Share id", showInGrid: false, showInExport: true, minWidth: 100 },
  {
    key: "sharedBy", label: "Shared by", showInGrid: true, showInExport: true, minWidth: 100,
    renderCell: (key: string, item: any) => (<PrincipalsCell columnId={key} item={item} />),
    getExportValue: (key: string, item: any) => { // TODO: ISharedLinksInWorkspaceReportItem
      return escapeForExport(item[key]);
    }
  },
  {
    key: "sharedBy", label: "Shared by upn", showInGrid: false, showInExport: true,
    getExportValue: (key: string, item: ISharedLinksInTenantReportItem) => {
      return item.sharedBy.upn;
    }
  },
  {
    key: "shared", label: "Shared on", showInGrid: true, showInExport: true,
    renderCell: (key: string, item: ISharedLinksInTenantReportItem) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />)
  },
  {
    key: "permissionType", label: "Permissions", showInGrid: true, showInExport: true, defaultWidth: 10,
    renderCell: (key: string, item: any) => (<SharingLinkPermissionsTypeCell permissionType={item.permissionType} />),
    getExportValue: (key: string, item: any) => { // TODO: ISharedLinksInWorkspaceReportItem
      return `"${getSharingLinkPermissionsTypePrimaryLabel(item[key])} - ${getSharingLinkPermissionsTypeSecondaryLabel(item[key])}"`;
    }
  },
  {
    key: "invitations", label: "Invitees", showInGrid: true, showInExport: false, minWidth: 100,
    renderCell: (key: string, item: any) => (<InvitationsCell columnId={key} item={item} />)
  },
  {
    key: "invitations", label: "Invitations", showInGrid: false, showInExport: true,
    getExportValue: (key: string, item: any) => { // TODO: ISharedLinksInWorkspaceReportItem
      return (item[key] as Array<any>)?.length === 0 ?
        "" :
        escapeForExport(item[key]);
    }
  },
  {
    key: "invitations", label: "Invitee upns", showInGrid: false, showInExport: true,
    getExportValue: (key: string, item: ISharedLinksInTenantReportItem) => {
      return item.invitations.map((invitation) => { return invitation.invitee.upn || invitation.invitee.email }).join(';');
    }
  },
  {
    key: "linkMembers", label: "Members", showInGrid: true, showInExport: true, minWidth: 100,
    renderCell: (key: string, item: any) => (<PrincipalsCell columnId={key} item={item} />),
    getExportValue: (key: string, item: any) => { // TODO: ISharedLinksInWorkspaceReportItem
      return (item[key] as Array<any>)?.length === 0 ?
        "" :
        escapeForExport(item[key]);
    }
  },
  {
    key: "linkMembers", label: "Member upns", showInGrid: false, showInExport: true,
    getExportValue: (key: string, item: ISharedLinksInTenantReportItem) => { // TODO: 
      return item.linkMembers.map((member) => { return member.upn }).join(';');
    }
  },
  {
    key: "expiration", label: "Expires", showInGrid: true, showInExport: true,
    renderCell: (key: string, item: ISharedLinksInTenantReportItem) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />),
    getExportValue: (key: string, item: ISharedLinksInTenantReportItem) => {
      return item.expiration ? item.expiration : "";
    }
  },
  {
    key: "requiresPassword", label: "Requires password", showInGrid: false, showInExport: true, minWidth: 100,
    renderCell: (key: string, item: ISharedLinksInTenantReportItem) => (<CheckedCell columnId={key} item={item} checkedValue={true} />),
    getExportValue: (key: string, item: ISharedLinksInTenantReportItem) => {
      return item.requiresPassword ? "Yes" : "No";
    }
  },
  {
    key: "passwordLastModified", label: "Password last modified", showInGrid: false, showInExport: true, minWidth: 100,
    getExportValue: (key: string, item: ISharedLinksInTenantReportItem) => {
      return item.passwordLastModified ? item.passwordLastModified : "";
    }
  },
  {
    key: "allowsAnonymousAccess", label: "Anonymous access", showInGrid: true, showInExport: true, minWidth: 100,
    renderCell: (key: string, item: ISharedLinksInTenantReportItem) => (<CheckedCell columnId={key} item={item} checkedValue={true} />),
    getExportValue: (key: string, item: ISharedLinksInTenantReportItem) => {
      return item.allowsAnonymousAccess ? "Yes" : "No";
    }
  },
  {
    key: "hasExternalGuestInvitees", label: "Has guests", showInGrid: true, showInExport: true, minWidth: 100,
    renderCell: (key: string, item: ISharedLinksInTenantReportItem) => (<CheckedCell columnId={key} item={item} checkedValue={true} />),
    getExportValue: (key: string, item: ISharedLinksInTenantReportItem) => {
      return item.hasExternalGuestInvitees ? "Yes" : "No";
    }
  },
];

export default function SharingLinksInTenantReport() {

  const [model, setModel] = useState<IRequestModel>({});
  // const [selectedWorkspace, setSelectedWorkspace] = useState<IWorkspace>();

  // const onWorkspaceChange = (value?: IWorkspace) => {
  //   console.log(`SharingLinks -> onWorkspaceChange -> `, value);
  //   setModel({ ...model, workspace: { siteUrl: value?.siteUrl } });
  //   setSelectedWorkspace(value);
  // }

  const isModelValid = (): boolean => {
    return true;
  }

  const reportSectionProps: IReportSectionProps<ISharedLinksInTenantReportItem, ISharedLinksInTenantReportDetails> = {
    columns: cols,
    summaryComponent: <></>,
    reportUrl: reportUrl,
    model: model,
    isModelValid: isModelValid(),
    noItemsMessage: "There are no sharing links found in the tenant.",
    exportFilenamePrefix: "SharingLinksInTenantReport"
  }

  return (
    <ReportSection {...reportSectionProps}>
      {/* <WorkspacePicker onChange={onWorkspaceChange} model={selectedWorkspace} /> */}
    </ReportSection>
  );
}
