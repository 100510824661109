import { Link, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { IStoragePercentageCellProps } from "./storage-percentage-cell.props";
//import PercentageCell from "../percentage-cell/percentage-cell";
import { getSizeLabel } from "lib/utils";
import PercentageBar from "components/common/percentage-bar/percentage-bar";

const layoutStyles = makeStyles({
    mainWrapper: {
    }
});

export default function StoragePercentageCell(props: IStoragePercentageCellProps) {
    const styles = layoutStyles();


    const [additionalLabel, setAdditionalLabel] = useState<string>("");

    useEffect(() => {
        const used = getSizeLabel(props.usedValue, props.usedValueUnits);
        const max = getSizeLabel(props.maxValue, props.maxValueUnits);
        setAdditionalLabel(`(${used} of ${max})`);
    }, [props.percentageUsed]);

    return (<>
        {props.percentageUsed === 0 &&
            <div>-</div>}
        {props.percentageUsed > 0 &&
            <PercentageBar
                value={props.percentageUsed}
                valueDecimalPlaces={2}
                showLabel={true}
                additionalLabel={additionalLabel} />
        }
    </>);
}