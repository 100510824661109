import { Link, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IWorkspaceLinkCellProps } from "./workspace-link-cell.props";
import { useContext, useEffect, useState } from "react";
import { PPAContext } from "context/ppa-context";


const layoutStyles = makeStyles({
    mainWrapper: {
    }
});

export default function WorkspaceLinkCell(props: IWorkspaceLinkCellProps) {
    const styles = layoutStyles();
    const ppa = useContext(PPAContext);

    const [label, setLabel] = useState<string>(props.name);

    // TEMP:
    const getTemplateNameKey = (template: string): string => {
        return `sharePointSiteTemplate${template?.replace('#', '')}Name`;
    }

    useEffect(() => {
        if (props.name) {
            setLabel(props.name);
        }
        else {
            setLabel(ppa.t ? ppa.t(getTemplateNameKey(props.template)) : props.template);
        }
    }, [props.name, props.template]);

    return (<div className={styles.mainWrapper}>
        <Link href={props.url} appearance="subtle" target="_blank">{label}</Link>
    </div>);
}