import { IReportRequest } from "@interfaces/reports/reports-common.interfaces";
import NavigationCards from "components/navigation/navigation-cards/navigation-cards";
import { getNavigationCardItems } from "lib/utils";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation, useSearchParams } from "react-router-dom";
import { TeamsFxContext } from "context/teams-context";
import { PPAContext } from "context/ppa-context";
import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
import Grid from "components/grid/grid";
import { ICommandBarItem, ITableColumn } from "@interfaces/common.interfaces";
import NavigateCell from "components/reports-common/report-results-grid-cells/navigate-cell/navigate-cell";
import PrincipalsCell from "components/reports-common/report-results-grid-cells/principals-cell/principals-cell";
import DateTimeCell from "components/reports-common/report-results-grid-cells/date-time-cell/date-time-cell";
import { makeStyles, shorthands } from "@fluentui/react-components";
import CommandBar from "components/command-bar/command-bar";
import PercentageBar from "components/common/percentage-bar/percentage-bar";
import IconWithLabel from "components/reports-common/report-results-grid-cells/common/icon-with-label/icon-with-label";
import { getReportRequestStatusIcon } from "lib/report-requests";
import {
    FilterRegular
} from "@fluentui/react-icons";

const defaultCommandBarItems: Array<ICommandBarItem> = [
    {
        id: 'openFilter',
        placement: 'right',
        type: 'button',
        order: 1,
        disabled: true,
        icon: <FilterRegular />
    }
];

const layoutStyles = makeStyles({
    mainWrapper: {
        flexGrow: 1,
        ...shorthands.padding('1em'),
    }
});

export default function ReportRequestsIndex() {

    const styles = layoutStyles();
    const location = useLocation();
    const [params] = useSearchParams();

    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    const ppa = useContext(PPAContext);

    const [reportRequests, setReportRequests] = useState<Array<IReportRequest>>([]);
    const [checkCount, setCheckCount] = useState<number>(0);

    //
    const cols: Array<ITableColumn<IReportRequest>> = [
        //{ key: "requestId", label: "Id", minWidth: 200 },
        {
            key: "reportName", label: "Report type", minWidth: 300,
            renderCell: (key: string, item: any) => (<NavigateCell label={item.reportName} url={item.reportRequestUrl} />)
        },
        {
            key: "status", label: "Status", minWidth: 100,
            renderCell: (key: string, item: IReportRequest) => (
                <IconWithLabel
                    icon={getReportRequestStatusIcon(item.status)}
                    label={ppa.t ? ppa.t(`reportRequestStatus${item.status}Label`) : ''} />
            )
        },
        {
            key: "percentageComplete", label: "Percentage complete", minWidth: 200,
            renderCell: (key: string, item: IReportRequest) => (
                <PercentageBar
                    value={item.percentageComplete}
                    valueDecimalPlaces={1}
                    showLabel={true} />
            )
        },
        {
            key: "requestedBy", label: "Requested by", minWidth: 200,
            renderCell: (key: string, item: IReportRequest) => (<PrincipalsCell columnId={key} item={item} />)
        },
        {
            key: "requested", label: "Requested", minWidth: 200,
            renderCell: (key: string, item: IReportRequest) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />)
        },
        {
            key: "generated", label: "Completed", minWidth: 200,
            renderCell: (key: string, item: IReportRequest) => (<DateTimeCell columnId={key} item={item} pattern="PPp" />)
        },
        { key: "actionRequestCount", label: "Action requests" },
    ];

    // Create the api client for each of the api calls.
    const apiClient = useMemo(() => {
        return createApiClient(
            ppa.appInstanceApiUrl || "",
            new BearerTokenAuthProvider(async () => (await teamsUserCredential?.getToken(""))!.token)
        );
    }, [ppa.appInstanceApiUrl]);

    const getReportRequests = async (): Promise<Array<any>> => {
        try {
            const response = await apiClient.get<Array<IReportRequest>>(`/api/report-requests`);

            const temp = response.data.map((item: IReportRequest) => {
                // TODO: Remove this when the ppa getReportPageUrl method is no longer optional!!
                const url = ppa.getReportPageUrl ? ppa.getReportPageUrl(item.reportType) : '/';
                const reportName = ppa.t ? ppa.t(item.reportType) : item.reportType;

                return {
                    ...item,
                    reportRequestUrl: `${url}/${item.requestId}`,
                    reportName: reportName
                };
            });

            setReportRequests(temp);
            setCheckCount(checkCount + 1);
            return temp;
        }
        catch (ex: unknown) {
            throw ex; // TODO: Handle the error, don't throw as this is the top level!!
        }
    }

    useEffect(() => {
        getReportRequests();
    }, []);

    useEffect(() => {
        // 
        var timerId: NodeJS.Timeout;
        const hasProcessingRequests: boolean = reportRequests.filter(r => r.status !== 3 && r.status !== 4).length > 0;
        const timeOut = hasProcessingRequests ? 2000 : 10000;

        timerId = setTimeout(() => {
            getReportRequests();
        }, timeOut);

        return () => clearTimeout(timerId);
    }, [checkCount]);

    {/* <div>category: {params.get(`category`)}</div>
            <div>report: {params.get(`report`)}</div>
            <div>requestor: {params.get(`requestor`)}</div> */}

    return (
        <>
            <CommandBar items={defaultCommandBarItems} />
            <section className={styles.mainWrapper} style={{ flexGrow: 1, display: 'flex', overflow: 'hidden', position: 'relative' }}>
                <Grid columns={cols} items={reportRequests} />
            </section>
        </>
    );
}
