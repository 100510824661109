import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { Card, CardHeader, Text, Image, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens, Caption1, Button, Spinner } from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
//import { IWorkspacePickerItemProps } from "./workspace-picker-item.props";
import { IWorkspacePickerResultsHeaderProps } from "./workspace-picker-results-header.props";


const layoutStyles = makeStyles({
    mainWrapper: {
        ...shorthands.padding('0', '0.5em', '0.5em', '0.5em'),
        display: 'flex',
        alignItems: 'center',
        minWidth: '200px'
    },
    caption: {
        marginLeft: '0.25em'
    }
});

export default function WorkspacePickerResultsHeader(props: IWorkspacePickerResultsHeaderProps) {
    const styles = layoutStyles();

    const [label, setLabel] = useState<string>();

    useEffect(() => {
        if (props.loading) {
            setLabel("Loading...");
        }
        else if (!props.results) {
            setLabel("Waiting for cache...");
        }
        else if (props.results) {
            if (props.results.length === 0) {
                setLabel(`No results found`);
            }
            else if (props.results.length === 1) {
                setLabel(`Showing ${props.results.length} result`);
            }
            else {
                setLabel(`Showing ${props.results.length} results`);
            }
        }
        else {
            setLabel("");
        }
    }, [props.loading, props.results]);

    return (
        <div className={styles.mainWrapper}>
            {props.loading && <Spinner size="extra-tiny" />}
            <Caption1 className={styles.caption}>
                {label}
            </Caption1>
        </div>
    );
}