import { Field, Popover, PopoverProps, PopoverSurface, PopoverTrigger, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IPrincipalPickerInputProps } from "./principal-picker-input.props";
import { useLocation } from "react-router-dom";
import { ChangeEvent, ChangeEventHandler, FocusEventHandler, useContext, useEffect, useMemo, useState } from "react";
import { TeamsFxContext } from "context/teams-context";
import { PPAContext } from "context/ppa-context";
import { IPrincipal } from "@interfaces/common.interfaces";
//import PrincipalPickerItem from "./principal-picker-item";
import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
import PrincipalCard from "components/common/principal-card/principal-card";

const layoutStyles = makeStyles({
    mainWrapper: {
        position: 'relative'
    },
    inputWrapper: {
        //backgroundColor: 'red',
        ...shorthands.padding('0.5em'),
        ...shorthands.borderWidth('1px'),
        ...shorthands.borderStyle('none', 'none', 'solid', 'none'),
        ...shorthands.borderColor(tokens.colorNeutralStrokeSubtle),
        // TODO: Transition bottom border like the Input component.
    },
    input: {
        ...shorthands.borderStyle('none'),
        backgroundColor: 'transparent',
        ...shorthands.outline(0),
        width: '100%',
        height: '2em',
        fontFamily: 'var(--fontFamilyBase)',
        fontSize: 'var(--fontSizeBase300)'
    }
});




export default function PrincipalPickerInput(props: IPrincipalPickerInputProps) {
    const styles = layoutStyles();

    const location = useLocation();
    const teamsUserCredential = useContext(TeamsFxContext).teamsUserCredential;
    const ppa = useContext(PPAContext);

    const [open, setOpen] = useState(false);
    const [results, setResults] = useState<Array<IPrincipal>>([]);

    // Create the api client for each of the api calls.
    const apiClient = useMemo(() => {
        console.log(`PrincipalPickerInput -> useMemo -> createApiClient -> `);
        return createApiClient(
            ppa.appInstanceApiUrl || "",
            new BearerTokenAuthProvider(async () => (await teamsUserCredential?.getToken(""))!.token)
        );
    }, [ppa.appInstanceApiUrl]);

    useEffect(() => {
        props.onResultsChange(results);
    }, [results]);

    const hasResults = (): boolean => {
        return results?.length > 0 == true;
    }

    const onSelectPrincipal = (user: IPrincipal) => {
        console.log(`PrincipalPickerInput -> onSelectuser -> `, user);
        props.onSelectPrincipal(user);
        setOpen(false);
    }

    const handleOpenChange: PopoverProps["onOpenChange"] = (e, data) => {
        setOpen(data.open || false);
    }

    const onChange: ChangeEventHandler<HTMLInputElement> = (ev: ChangeEvent<HTMLInputElement>) => {
        console.log(`PrincipalPickerInput -> onChange -> `, ev.target.value);
        props.onChange(ev.target.value);

        //
        const search = encodeURIComponent(ev.target.value);

        //
        const searchSites = async () => {
            try {

                //
                let principalTypes = ``;
                let userType = ``;

                if (props.principalTypes) {
                    principalTypes = `&principalTypes=${props.principalTypes.join(`,`)}`;
                }
                if (props.userType) {
                    userType = `&userType=${props.userType}`;
                }

                const response = await apiClient.get<Array<IPrincipal>>(`/api/principals/search?search=${search}${principalTypes}${userType}`);
                setResults(response.data);
                setOpen(true);
            }
            catch (ex: unknown) {
                throw ex; // TODO: Handle the error, don't throw as this is the top level!!
            }
        }
        if (search.length > 0) {
            searchSites();
        }
        else {
            setResults([]);
            setOpen(false);
        }

        //console.log(`WorkspacePickerInput -> onC -> `, ev, ev.target.value);
    }

    // FocusEvent<HTMLInputElement>
    const onFocus: FocusEventHandler<HTMLInputElement> = (ev: any) => {
        console.log(`PrincipalPickerInput -> onFocus -> `, ev);
        if (results?.length > 0) {
            setOpen(true);
            // TODO: This conflicts with handleOpenChange
        }
    }

    return (
        <>
            {props.visible && <div className={styles.mainWrapper}>
                <Field validationMessage="">
                    <div className={styles.inputWrapper}>
                        <input
                            className={styles.input}
                            id={props.inputId}
                            onChange={onChange}
                            onFocus={onFocus}
                            autoComplete="off"
                            placeholder="Type to filter..." />
                    </div>
                </Field>
                <Popover inline trapFocus open={open} onOpenChange={handleOpenChange} size="large" positioning={"below-start"} >
                    <PopoverTrigger disableButtonEnhancement>
                        <span></span>
                    </PopoverTrigger>
                    <PopoverSurface>
                        <div style={{ width: '100%', overflowY: 'auto', maxHeight: '300px' }}>
                            {!hasResults() &&
                                <div>No users/groups to show</div>}
                            {results?.map((user: IPrincipal) => (
                                // <PrincipalPickerItem
                                //     key={user.objectId}
                                //     item={user}
                                //     onSelect={onSelectPrincipal}
                                //     isResultItem={true} />
                                <PrincipalCard
                                    key={user.objectId}
                                    item={user}
                                    onSelect={onSelectPrincipal}
                                    style="subtle" />
                            ))}
                        </div>
                    </PopoverSurface>
                </Popover>
            </div>
            }
        </>
    );
}