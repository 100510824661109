import { PropsWithChildren, useEffect, useState } from "react";
import { ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
    FluentIcon,
    QuestionCircleRegular
} from "@fluentui/react-icons";
import IconWithLabel from "../../common/icon-with-label/icon-with-label";
import { getSharingLinkPermissionsTypeIcon, getSharingLinkPermissionsTypePrimaryLabel, getSharingLinkPermissionsTypeSecondaryLabel } from "lib/shared-links";

const layoutStyles = makeStyles({
    mainWrapper: {
    }
});

export default function SharingLinkPermissionsTypeCell(props: { permissionType: number }) {
    const styles = layoutStyles();

    const [icon, setIcon] = useState<FluentIcon>(QuestionCircleRegular);
    const [primaryLabel, setPrimaryLabel] = useState<string>("");
    const [secondaryLabel, setSecondaryLabel] = useState<string>("");

    useEffect(() => {
        setIcon(getSharingLinkPermissionsTypeIcon(props.permissionType));
        setPrimaryLabel(getSharingLinkPermissionsTypePrimaryLabel(props.permissionType));
        setSecondaryLabel(getSharingLinkPermissionsTypeSecondaryLabel(props.permissionType));
    }, [props.permissionType]);

    return (<div className={styles.mainWrapper}>
        <IconWithLabel
            icon={icon}
            label={primaryLabel}
            secondaryLabel={secondaryLabel} />
    </div>);
}