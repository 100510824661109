import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { createElement } from "react";
import UnknownActionForm from "components/actions/unknown/unknown-action-form";
import UnknownActionResult from "components/actions/unknown/unknown-action-result";
import RemoveOrphanedUsersActionForm from "components/actions/orphaned-users/remove-orphaned-users-action/remove-orphaned-users-action-form"
import RemoveOrphanedUsersActionResult from "components/actions/orphaned-users/remove-orphaned-users-action/remove-orphaned-users-action-result";


export const getActionFormComponent = (actionType: number): ReactJSXElement => {
    switch (actionType) {
        case 1:
            return createElement(RemoveOrphanedUsersActionForm);
        default:
            return createElement(UnknownActionForm);
    }
}

export const getActionResultComponent = (actionType: number): ReactJSXElement => {
    switch (actionType) {
        case 1:
            return createElement(RemoveOrphanedUsersActionResult);
        default:
            return createElement(UnknownActionResult);
    }
}

export const getActionTypeNameKey = (id: number): string => {
    return `actionType${id}Name`;
};